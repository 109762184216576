import './App.css';

import React, { Component } from 'react';
//import { Dropdown } from 'react-native-material-dropdown';
import { Dimensions, FlatList, Image, Linking, Pressable, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
//import { Dropdown } from 'react-native-element-dropdown';
//import Dropdown from 'react-dropdown';
//import DeclineModal from './DeclineModal.js';
//import { SelectList } from 'react-native-dropdown-select-list'
// import DropDownPicker from 'react-native-dropdown-picker';

import Cookies from 'universal-cookie';
import DeviceInfo from 'react-native-device-info';
import { sha3_256 } from 'js-sha3';
import { SafeSet, SafeGet } from './bracketNotationReplacement.js';

const jwtSign = require('jwt-encode');

const App = () => {
  const BOOKING_OPEN_TIME = 7200000;
  const BOOKING_DELAY_TIME = 7200000;
  const CHAT_REFRESH_DELAY = 10000;
  const [BASE_DOMAIN_NAME, onChangeBaseDomainName] = React.useState("");
  const [BASE_CUSTOMER_DOMAIN_NAME, onChangeBaseCustomerDomainName] = React.useState("");
  const [APP_ASSETS_BUCKET, onChangeAppAssetsBucket] = React.useState("");
  const [CALENDAR_BUCKET, onChangeCalendarBucket] = React.useState("");
  const [MY_DOMAIN_NAME, onChangeMyDomainName] = React.useState("");

  const [deviceID, onChangeDeviceID] = React.useState("");
  const [previousPage, onChangePreviousPage] = React.useState([]);
  const [page, onChangePage] = React.useState("");
  const [email, onChangeEmail] = React.useState("");
  const [password, onChangePassword] = React.useState("");
  const [confirmPassword, onChangeConfirmPassword] = React.useState("");
  const [employeeInfo, onChangeEmployeeInfo] = React.useState("");
  const [myChats, onChangeChatInfo] = React.useState([]);
  const [nextQuestionnairePage, onNextQuestionnairePage] = React.useState("")
  const [attend, onSetAttend] = React.useState([
    {answerID:1, caption:'Yes', value:true, selected: false},
    {answerID:2, caption:'No', value:false, selected: false}
  ]);
  const [qRadio, onSetQRadio] = React.useState([]);
  const [qMultiselect, onSetQMultiselect] = React.useState([]);
  const [interestsMultiselect, onSetInterestsMultiselect] = React.useState([]);
  const [bookingRating, onChangeBookingRating] = React.useState("");
  const [groupRating, onChangeGroupRating] = React.useState("");
  const [ratingComment, onChangeRatingComment] = React.useState("");
  const [chatMessage, onChangeChatMessage] = React.useState("");
  const [popupView, onChangePopupView] = React.useState("");
  const scrollInfo = React.useRef(null);
  const flatListRef = React.useRef(null);
  const chatRefreshTimer = React.useRef(null);
  const [helpSubject, onChangeHelpSubject] = React.useState("");
  const [helpDetails, onChangeHelpDetails] = React.useState("");
  const [processingLogin, onChangeProcessingLogin] = React.useState("");
  const [processingRefresh, onChangeProcessingRefresh] = React.useState("");
  const [declineBookingReasons, onSetDeclineBookingReasons] = React.useState([]);

  const cookies = new Cookies();

  //*******************************************************************************************************************
  // radio buttons adapted from https://dev.to/aneeqakhan/create-a-radio-button-from-the-scratch-in-react-native-3662
  const onRadioBtnClick = (item, optionList, changeFunction) => {
    let updatedState = optionList.map((radioItem) =>
      radioItem.answerID === item.answerID
        ? { ...radioItem, selected: true }
        : { ...radioItem, selected: false }
    );
    changeFunction(updatedState);
  };

  const InitializeRadio = (selection, optionList, changeFunction) => {
    // skip if they didn't actually select one yet
    if( selection === "" || selection === null )
      return;

    let updatedState = optionList.map((radioItem) =>
      radioItem.answerID === selection
        ? { ...radioItem, selected: true }
        : { ...radioItem, selected: false }
    );
    changeFunction(updatedState);
  }

  const RadioButton = ({ onPress, selected, skinny, survey, children }) => {
    return (
      <TouchableOpacity style={ [styles.radioContainer, (skinny ? styles.radioContainerSkinny : null), (selected ? styles.radioContainerOn : styles.radioContainerOff)] } onPress={onPress}>
        <View accessibilityLabel={ (selected ? "" : "Not ") + "Selected" } style={ [styles.radioButton, (skinny ? styles.radioButtonSkinny : null), selected ? styles.radioButtonOn : styles.radioButtonOff] } />
        <Text style={ [survey ? styles.surveyRadioButtonText : styles.radioButtonText, (skinny ? styles.radioButtonTextSkinny : null)] }>{children}</Text>
      </TouchableOpacity>
    );
  };
  // radio buttons adapted from https://dev.to/aneeqakhan/create-a-radio-button-from-the-scratch-in-react-native-3662
  //*******************************************************************************************************************

  const onMultiselectClick = (item, optionList, active, changeFunction) => {
    let updatedState = optionList.map((selectItem) =>
      (selectItem.answerID === item.answerID)
        ? { ...selectItem, selected: active }
        : selectItem
    );

    // bonus logic for exclusionary answers
    if( active ) {
      // if they just turned ON an exclusionary answer, turn off everything else
      if( item.isExclusionary ) {
        updatedState = updatedState.map((selectItem) =>
          (selectItem.answerID === item.answerID)
            ? selectItem
            : { ...selectItem, selected: false }
	    );
      // they just turned ON something else, so turn off the exclusionary answer
      } else {
        updatedState = updatedState.map((selectItem) =>
          (selectItem.isExclusionary)
            ? { ...selectItem, selected: false }
            : selectItem
	    );
	  }
    }

    changeFunction(updatedState);
  };

  const InitializeMultiselect = (json, optionList, changeFunction) => {
    // skip if they didn't actually select one yet
    if( json === "" || json === null || (Array.isArray(json) && (json.length == 0)) )
      return;

    let updatedState = optionList.map((selectItem) =>
      (json.includes(selectItem.answerID) === true)
        ? { ...selectItem, selected: true }
        : { ...selectItem, selected: false }
    );
    changeFunction(updatedState);
  }

  const GetCountSelected = (optionList) => {
    let count = 0;
	optionList.forEach((selectItem) => {
      if( selectItem.selected ) {
        count++;
      }
    });
	return count;
  }

  const GetMultiselectJSON = (optionList) => {
    let json = [];
	optionList.forEach((selectItem) => {
      if( selectItem.selected ) {
        json.push(selectItem.answerID);
      }
    });
    return json;
  }

  const CheckboxButton = ({ onPress, selected, skinny, children }) => {
    return (
      <TouchableOpacity style={ [styles.radioContainer, (skinny ? styles.radioContainerSkinny : null), (selected ? styles.radioContainerOn : styles.radioContainerOff)] } onPress={onPress}>
        <View accessibilityLabel={ (selected ? "" : "Not ") + "Selected" } style={ [styles.checkboxButton, (skinny ? styles.checkboxButtonSkinny : null), selected ? styles.checkboxButtonOn : styles.radioButtonOff] }>
          { selected ? <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/whiteCheck.svg") }} style={ styles.checkboxButtonIcon } /> : null }
        </View>
        <Text style={ [styles.radioButtonText, (skinny ? styles.radioButtonTextSkinny : null)] }>{children}</Text>
      </TouchableOpacity>
    );
  };

  const onInterestBtnClick = (item, optionList, active, changeFunction) => {
    let updatedState = optionList.map((selectItem) =>
      (selectItem.interestID === item.interestID)
        ? { ...selectItem, selected: active }
        : selectItem
    );

    changeFunction(updatedState);
  };

  const InitializeInterests = (interestData) => {
    // skip if they didn't actually select one yet
    if( interestData === "" || interestData === null || (Array.isArray(interestData) && (interestData.length == 0)) )
      return;

    let answers = [];

    interestData.categories.forEach((thisCategory) => {
      thisCategory.interests.forEach((thisInterest) => {
        answers.push({...thisInterest, selected:(interestData.myResponse.includes(thisInterest.interestID)), answerID:thisInterest.interestID});
      });
    });

    onSetInterestsMultiselect(answers);
  }

  const InterestButton = ({ onPress, selected, children }) => {
    return (
      <TouchableOpacity style={ interests.interestButton } onPress={onPress}>
        <View accessibilityLabel={ (selected ? "" : "Not ") + "Selected" } style={ [interests.checkboxButton, selected ? interests.checkboxButtonOn : null] }>
          { selected ? <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/whiteCheck.svg") }} style={ styles.checkboxButtonIcon } /> : null }
        </View>
        <Image source={{ "uri":children.URL }} style={ [interests.interestScreenshot, (selected ? interests.interestScreenshotOn : null)] } />
        <Text style={ interests.interestLabel }>{children.caption}</Text>
      </TouchableOpacity>
    );
  };

  if( deviceID === "" ) {
    if( cookies.get("deviceID") ) {
      onChangeDeviceID(cookies.get("deviceID"));
    } else {
      const devID = sha3_256(DeviceInfo.getUniqueId() + (new Date().getTime())).toString();
      onChangeDeviceID(devID);
      cookies.set("deviceID", devID, { path: '/' } );
    }
  } else if ( page === "" && cookies.get("bfmsID") && cookies.get("myServer") ) {
    if( MY_DOMAIN_NAME === "" ) {
      onChangeMyDomainName( cookies.get("myServer") );
    } else if( processingRefresh !== true ) {
      RefreshSession();
    }
  }
  // find the domains this app should be using for various tasks
  if( BASE_DOMAIN_NAME === "" ) {
    fetch('config.txt').then((response) => response.json()).then((responseJson) => {
      onChangeBaseDomainName(responseJson.BASE_DOMAIN);
      onChangeBaseCustomerDomainName(responseJson.BASE_CUSTOMER_DOMAIN);
      onChangeCalendarBucket(responseJson.CALENDAR_BUCKET);
      onChangeAppAssetsBucket(responseJson.APP_ASSETS_BUCKET);
    }).catch((error) => {
      console.error(error);
    });
  }

  function GenerateRotatingToken() {
    let timeStr = Math.floor(Date.now() / 1000).toString();
    let hash = sha3_256("BelongForMe" + timeStr);
    timeStr = timeStr.split("").reverse().join("");
    let offset = parseInt(timeStr.substring(0,2));
    let token = timeStr.substring(0,2) + hash.substring(2, 2 + (offset % 30)) + timeStr.substring(2,10) + hash.substring((offset % 30) + 10);
    return token;
  }

  function GetMapLink(address) {
    return "https://www.google.com/maps/dir//" + encodeURI(address.line1 + (address.line2 ? (", " + address.line2) : "") + ", " + address.city + ", " + address.state + " " + address.zip);
  }

  function SubmitLogin() {
    onChangeProcessingLogin(true);
    fetch(BASE_DOMAIN_NAME + '/login.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({task: 'login', email: email, pwHash: sha3_256("veruca" + password), deviceID: deviceID, rotatingToken: GenerateRotatingToken()})
    }).then((response) => response.json()).then((responseJson) => {
      onChangeProcessingLogin("");
      if( responseJson.success !== true ) {
        alert(responseJson.message);
      } else {
        cookies.set("bfmsID", responseJson.sessionID, { path: '/' } );
        cookies.set("myServer", responseJson.myServer, { path: '/' } );
        FinishLogin(responseJson);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  function SubmitClaimAccount() {
    let failureReason = TestPassword();
    if( failureReason ) {
      alert(failureReason);
	  return;
    }
    fetch(MY_DOMAIN_NAME + '/claimAccount.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({task: 'claim', pwHash: sha3_256("veruca" + password), deviceID: deviceID, claimHash: queryParams.get('referral').substring(0,64), employeeID: queryParams.get('referral').substring(64)})
    }).then((response) => response.json()).then((responseJson) => {
      if( responseJson.success !== true ) {
        alert(responseJson.message);
      } else {
        window.history.pushState({}, document.title, "/");
        cookies.set("bfmsID", responseJson.sessionID, { path: '/' } );
        FinishLogin(responseJson);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  function SubmitResetPassword() {
    let failureReason = TestPassword();
    if( failureReason ) {
      alert(failureReason);
	  return;
    }
    fetch(MY_DOMAIN_NAME + '/forgotPassword.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({task: 'resetPassword', pwHash: sha3_256("veruca" + password), resetHash: queryParams.get('resetCode').substring(0,64), employeeID: queryParams.get('resetCode').substring(64), rotatingToken: GenerateRotatingToken()})
    }).then((response) => response.json()).then((responseJson) => {
      if( responseJson.success ) {
        alert(responseJson.message);
        window.location = "https://" + window.location.hostname;
      } else {
        alert(responseJson);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  function TestPassword() {
    if( password.length < 8 ) {
      return "Passwords must be at least 8 characters in length.";
    } else if( password.match(/[A-Z]/g) === null ) {
      return "Passwords must contain at least one capital letter.";
    } else if( password.match(/[a-z]/g) === null ) {
      return "Passwords must contain at least one lowercase letter.";
    } else if( password.match(/[^A-Za-z]/g) === null ) {
      return "Passwords must contain at least one digit or special character.";
    }
    return false;
  }

  function PrimeQuestionnaire(question) {
    let answers = []
    question.answers.forEach((thisAnswer) => {
      answers.push({...thisAnswer, selected:(question.myResponse.includes(thisAnswer.answerID)), value:thisAnswer.answerID});
    });
    if( question.maxAnswers === 1 ) {
      onSetQRadio(answers);
      InitializeRadio(question.myResponse[0] ?? null, answers, onSetQRadio);
    } else {
      onSetQMultiselect(question.answers);
      InitializeMultiselect(question.myResponse ?? null, answers, onSetQMultiselect);
    }
  }

  function FinishLogin(responseJson) {
    HandleEmployeeResponse(responseJson);
    if( responseJson.hasOwnProperty("myServer") ) {
      onChangeMyDomainName(responseJson.myServer);
    }

    let declineReasons = []
    responseJson.employeeInfo.declineBookingReasons.forEach((reason) => {
      declineReasons.push({...reason, selected:false});
    });
    onSetDeclineBookingReasons(declineReasons);

    let nextPage = "landingPage", pageNum = 0;
    responseJson.employeeInfo.questionnaireData.forEach((question) => {
      if( (nextPage === "landingPage") && (question.myResponse.length < question.minAnswers) ) {
        PrimeQuestionnaire(question);
        nextPage = "questionnaire" + pageNum;
      }
      pageNum++;
    });
    if( (nextPage === "landingPage") && (responseJson.employeeInfo.interestData.myResponse.length < responseJson.employeeInfo.interestData.minAnswers) ) {
      nextPage = "interests";
    }

    if(nextPage !== "landingPage") {
      onNextQuestionnairePage(nextPage);
      nextPage = "meetCoco";
    }

    // see if they have an active booking. if so, switch to upcoming instead of the landing page
    responseJson.employeeInfo.myAcceptedBookings.forEach((thisBooking) => {
      if( (nextPage === "landingPage") && (((thisBooking.unixTime * 1000) - BOOKING_OPEN_TIME) <= Date.now()) && (((thisBooking.unixTime * 1000) + BOOKING_DELAY_TIME) > Date.now()) ) {
        nextPage = "upcomingPage";
	  }
    });
    onChangePage(nextPage);
  }

  function HandleEmployeeResponse(responseJson) {
    responseJson = JSON.parse(JSON.stringify(responseJson).replace(/&apos;/g, "'").replace(/&quot;/g, "\\\""));
    onChangeEmployeeInfo(responseJson.employeeInfo);
    if( (email === "") && (responseJson.employeeInfo.email !== "") ) {
      onChangeEmail( responseJson.employeeInfo.email );
    }
    InitializeInterests(responseJson.employeeInfo.interestData);
  }

  function HandleQuestionnaireResponse(responseJson) {
    responseJson = JSON.parse(JSON.stringify(responseJson).replace(/&apos;/g, "'").replace(/&quot;/g, "\\\""));
    const newQuestionnaireInfo = responseJson.questionnaireInfo;
    const newEmployeeInfo = { ...employeeInfo, ...newQuestionnaireInfo };
    onChangeEmployeeInfo(newEmployeeInfo);
    InitializeInterests(employeeInfo.interestData);
  }

  function HandleBookingResponse(responseJson) {
    responseJson = JSON.parse(JSON.stringify(responseJson).replace(/&apos;/g, "'").replace(/&quot;/g, "\\\""));
    const newBookingInfo = responseJson.bookingInfo;
    const newChatInfo = responseJson.chatInfo;
    const newEmployeeInfo = { ...employeeInfo, ...newBookingInfo, ...newChatInfo };
    onChangeEmployeeInfo(newEmployeeInfo);
  }
  
  function HandleChatResponse(responseJson) {
    responseJson = JSON.parse(JSON.stringify(responseJson).replace(/&apos;/g, "'").replace(/&quot;/g, "\\\""));
    onChangeChatInfo(responseJson.chatInfo?.myChats);
  }

  function SubmitLogout() {
    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "logout"
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      cookies.remove("bfmsID", { path: '/' } );
      cookies.remove("myServer", { path: '/' } );
      cookies.remove("deviceID", { path: '/' } );
      onChangeDeviceID("");
      onChangeEmployeeInfo("");
      onChangePreviousPage([]);
      onChangePage("login");
    }).catch((error) => {
      console.error(error);
    });
  }

  function SubmitHelp() {
    fetch(BASE_DOMAIN_NAME + '/help.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({task: 'help', email: email, subject: helpSubject, details: helpDetails, rotatingToken: GenerateRotatingToken()})
    }).then((response) => response.json()).then((responseJson) => {
      if( responseJson.success !== true ) {
        alert(responseJson.message);
      } else {
        onChangePopupView(
          <View style={ styles.popup }>
            <View style={ styles.popupPanel }></View>
            <View style={ helpPopup.panel }>
              <Text style={ helpPopup.title }>Submitted!</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoWavingFull.svg") }} accessibilityLabel="Success" style={ helpPopup.character } />
              <Text style={ helpPopup.description }>Your issue has been reported.{"\n"}You'll hear from me soon!</Text>
              <TouchableOpacity style={ [helpPopup.closeButton, styles.buttonActive] } onPress={() => { onChangePopupView(""); onChangePage("profile"); } }>
                <Text style={ styles.whiteButtonLabel }>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        );
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  function SubmitForgotPassword() {
    fetch(BASE_DOMAIN_NAME + '/forgotPassword.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({task: 'forgotPassword', email: email, rotatingToken: GenerateRotatingToken()})
    }).then((response) => response.json()).then((responseJson) => {
      if( responseJson.success !== true ) {
        alert(responseJson.message);
      } else {
        onChangePopupView(
          <View style={ styles.popup }>
            <View style={ styles.popupPanel }></View>
            <View style={ helpPopup.panel }>
              <Text style={ helpPopup.title }>Submitted!</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoWavingFull.svg") }} accessibilityLabel="Success" style={ helpPopup.character } />
              <Text style={ helpPopup.description }>Your request has been received.{"\n"}You'll hear from me soon!</Text>
              <TouchableOpacity style={ [helpPopup.closeButton, styles.buttonActive] } onPress={() => { onChangePopupView(""); onChangePage("login"); } }>
                <Text style={ styles.whiteButtonLabel }>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        );
      }
    }).catch((error) => {
      console.log(error)
      console.error(error);
    });
  }

  function LikeEvent(eventID, liked) {
    onChangePopupView("");

    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "updateEventLike",
        eventID: eventID,
        liked: liked
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      if (responseJson.success) {
        onChangeEmployeeInfo(responseJson.employeeInfo);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }


  function AcceptBooking(groupID) {
    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "acceptBooking",
        groupID: groupID,
      })
    }).then((response) => VerifySession(response)).then((responseJson) => {
      if( responseJson.success ) {
        let thisBooking = null;
        responseJson.bookingInfo.myAcceptedBookings.forEach((loopBooking) => {
          if( loopBooking.groupID === groupID ) {
            thisBooking = loopBooking;
          }
        });
        let d = new Date( parseInt(thisBooking.unixTime) * 1000 );
        let d2 = new Date( (parseInt(thisBooking.unixTime) + parseInt((thisBooking.timeTotal !== undefined) ? thisBooking.timeTotal : 0) * 60) * 1000 );
        let startZulu = String(d.getUTCFullYear()) + ((d.getUTCMonth() < 9) ? "0" : "") + String(d.getUTCMonth() + 1) + ((d.getUTCDate() < 10) ? "0" : "") + String(d.getUTCDate()) + "T" +
                        ((d.getUTCHours() < 10) ? "0" : "") + String(d.getUTCHours()) + ((d.getUTCMinutes() < 10) ? "0" : "") + String(d.getUTCMinutes()) + "00Z";
        let endZulu = String(d2.getUTCFullYear()) + ((d2.getUTCMonth() < 9) ? "0" : "") + String(d2.getUTCMonth() + 1) + ((d2.getUTCDate() < 10) ? "0" : "") + String(d2.getUTCDate()) + "T" +
                      ((d2.getUTCHours() < 10) ? "0" : "") + String(d2.getUTCHours()) + ((d2.getUTCMinutes() < 10) ? "0" : "") + String(d2.getUTCMinutes()) + "00Z";
        let startOutlook = String(d.getUTCFullYear()) + "-" + ((d.getUTCMonth() < 9) ? "0" : "") + String(d.getUTCMonth() + 1) + "-" + ((d.getUTCDate() < 10) ? "0" : "") + String(d.getUTCDate()) +
                           "T" + ((d.getUTCHours() < 10) ? "0" : "") + String(d.getUTCHours()) + ":" + ((d.getUTCMinutes() < 10) ? "0" : "") + String(d.getUTCMinutes()) + ":00+00:00";
        let endOutlook = String(d2.getUTCFullYear()) + "-" + ((d2.getUTCMonth() < 9) ? "0" : "") + String(d2.getUTCMonth() + 1) + "-" + ((d2.getUTCDate() < 10) ? "0" : "") + String(d2.getUTCDate()) +
                         "T" + ((d2.getUTCHours() < 10) ? "0" : "") + String(d2.getUTCHours()) + ":" + ((d2.getUTCMinutes() < 10) ? "0" : "") + String(d2.getUTCMinutes()) + ":00+00:00";
        let addressStr = thisBooking.venue.address.line1 + ", " + thisBooking.venue.address.city + ", " + thisBooking.venue.address.state + " " + thisBooking.venue.address.zip;
        let googleLink = "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=" + startZulu + "%2F" + endZulu + "&details=" + encodeURIComponent(thisBooking.event.description) +
                         "&location=" + encodeURIComponent(addressStr) + "&text=" + encodeURIComponent(thisBooking.event.shortTitle);
        let outlookLink = "https://outlook.office.com/calendar/deeplink/compose?body=" + encodeURIComponent(thisBooking.event.description) + "&enddt=" + encodeURIComponent(endOutlook) +
                          "&location=" + encodeURIComponent(addressStr) + "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=" + encodeURIComponent(startOutlook) + "&subject=" + encodeURIComponent(thisBooking.event.shortTitle);
        onChangePopupView(
          <View style={ styles.popup }>
            <View style={ styles.popupPanel }></View>
            <View style={ acceptedPopup.panel }>
              <Text style={ acceptedPopup.title }>You have RSVP'd!</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoWavingFull.svg") }} accessibilityLabel="Hooray!" style={ acceptedPopup.character } />
              <Text style={ acceptedPopup.description }>Make sure you add the date to your{"\n"}calendar, so you don't forget!</Text>
              <a href={ CALENDAR_BUCKET + "/" + thisBooking.regionID + "/" + thisBooking.sourceID + ".ics" } target="_blank" rel="noopener noreferrer">
                <TouchableOpacity style={ [acceptedPopup.calendarButton, acceptedPopup.iCalButton] }>
                  <Text style={ [acceptedPopup.calendarButtonLabel, acceptedPopup.iCalLabel] }>Add to iCal</Text>
                </TouchableOpacity>
              </a>
              <a href={ outlookLink } target="_blank" rel="noopener noreferrer">
                <TouchableOpacity style={ [acceptedPopup.calendarButton, acceptedPopup.outlookButton] }>
                  <Text style={ [acceptedPopup.calendarButtonLabel, acceptedPopup.outlookLabel] }>Add to Outlook Calendar</Text>
                </TouchableOpacity>
              </a>
              <a href={ googleLink } target="_blank" rel="noopener noreferrer">
                <TouchableOpacity style={ [acceptedPopup.calendarButton, acceptedPopup.googleButton] }>
                  <Text style={ [acceptedPopup.calendarButtonLabel, acceptedPopup.googleLabel] }>Add to Google Calendar</Text>
                </TouchableOpacity>
              </a>
              <TouchableOpacity style={ [acceptedPopup.closeButton, styles.buttonActive] } onPress={() => { onChangePopupView(""); onChangePage("landingPage"); }}>
                <Text style={ styles.whiteButtonLabel }>Return to Home</Text>
              </TouchableOpacity>
            </View>
          </View>
        );
        HandleBookingResponse(responseJson);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  function PreflightDeclineBooking(groupID) {  
    onChangePopupView(<DeclineModal groupID={groupID} declineBookingReasons={declineBookingReasons} handleOnChangePopupView={onChangePopupView} handleDeclineBooking={DeclineBooking} handleOnRadioBtnClick={onRadioBtnClick}/>);
  }

  const DeclineModal = ({ groupID, declineBookingReasons, handleOnChangePopupView, handleDeclineBooking, handleOnRadioBtnClick }) => {
    const [declineReasons, onChangeDeclineBooking] = React.useState(declineBookingReasons);
    const [otherComment, onChangeOtherComment] = React.useState("");

    let enableButton = false;
    let requireComment = false;
    let answerID;
    declineReasons.forEach((item) => { if( item.selected ) { 
      enableButton = true; 
      requireComment = item.commentRequired;
      answerID = item.answerID;
    }});
    if (requireComment && otherComment.length === 0 ) {
      enableButton = false;
    }

    return (
      <View style={ styles.popup }>
        <View style={ styles.popupPanel }></View>
        <View style={ confirmDeclineEventPopup.panel }>
          <Text style={ confirmDeclineEventPopup.title }>Are you sure?</Text>
          <Text style={ confirmDeclineEventPopup.subtitle }>What is the reason you are declining this invitation?<Text>(Required)</Text></Text>
          <View style={confirmDeclineEventPopup.selectionGroup}>
            <View style={confirmDeclineEventPopup.choiceGroup}>
              {declineReasons.map((item) => (
                <View style={ [questionnaire.optionButton, questionnaire.optionButtonSkinny] }>
                  <RadioButton onPress={() => { handleOnRadioBtnClick(item, declineReasons, onChangeDeclineBooking); }} selected={item.selected} skinny={true} survey={false} key={item.answerID} >
                    {item.caption}
                  </RadioButton>
                </View>
              ))}
              <View style={[confirmDeclineEventPopup.commentBox ]}>
                { (otherComment === "") ? <Text style={ [loginPage.ghostLabel, surveyPage.commentLabel, confirmDeclineEventPopup.reasonLabel] }>Tell us more</Text> : null }
                <TextInput style={ [surveyPage.commentInput, confirmDeclineEventPopup.commentInput] } onChangeText={onChangeOtherComment} value={otherComment} multiline="true" />
              </View> 
            </View>
          </View>
          <TouchableOpacity style={ [confirmDeclineEventPopup.confirmButton, (enableButton ? styles.buttonActive : styles.buttonInactive)] } onPress={() => handleDeclineBooking( groupID, answerID, otherComment )} disabled={ !enableButton }>
            <Text style={ styles.whiteButtonLabel }>Yes, Decline Event</Text>
          </TouchableOpacity>
          <TouchableOpacity style={ [confirmDeclineEventPopup.cancelButton, styles.whiteButtonActive] } onPress={() => handleOnChangePopupView("")}>
            <Text style={ styles.blueButtonLabel }>Return to Home</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  function DeclineBooking(groupID, answerID, otherComment) {
    onChangePopupView("");

    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "declineBooking",
        groupID: groupID,
        answerID: answerID,
        note: otherComment
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      if( responseJson.success ) {
        onChangePopupView(<DeclineConfirmModal/>);
        HandleBookingResponse(responseJson);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  const DeclineConfirmModal = () => {
    return (
      <View style={ styles.popup }>
        <View style={ styles.popupPanel }></View>
        <View style={ declinePopup.panel }>
          <Text style={ confirmDeclineEventPopup.title }>Aw shucks...</Text>
          <Text style={ confirmDeclineEventPopup.subtitle }> We'll see you next time then!{"\n"}I'll send you some more event{"\n"}recommendations soon.</Text>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoSadFull.svg") }} accessibilityLabel="Disappointment" style={ confirmDeclineEventPopup.character } />
          <TouchableOpacity style={ [declinePopup.closeButton, styles.buttonActive] } onPress={() => { onChangePopupView(""); onChangePage("landingPage"); } } >
            <Text style={ styles.whiteButtonLabel }>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  function BookingCheckin(groupID, meetingSpot, addPreviousPageToStack=false) {
    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "bookingCheckin",
        groupID: groupID
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      if( responseJson.success ) {
        onChangePopupView(
          <View style={ styles.popup }>
            <View style={ styles.popupPanel }></View>
            <View style={ checkinPopup.panel }>
              <Text style={ checkinPopup.title }>You're Checked In!</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoExcitedFull.svg") }} accessibilityLabel="Hooray!" style={ checkinPopup.character } />
              <Text style={ checkinPopup.description }><Text style={ styles.bold }>Head to your Meeting Location:</Text>{"\n" + meetingSpot + "\n\n"}<Text style={ styles.bold }>Tip:</Text> Go to the chat and tell your peers what{"\n"}you're wearing so they can find you.</Text>
              <TouchableOpacity style={ [checkinPopup.chatButton, styles.buttonActive] } onPress={() => { onChangePopupView(""); scrollInfo.current = {"offset":0, "result":0}; if(addPreviousPageToStack) { onChangePreviousPage([...previousPage, page]); } onChangePage("chat" + groupID); RefreshChat(groupID);} }>
                <Text style={ styles.whiteButtonLabel }>Go to Chat</Text>
              </TouchableOpacity>
              <TouchableOpacity style={ [checkinPopup.closeButton, styles.whiteButtonActive] } onPress={() => { onChangePopupView(""); if(addPreviousPageToStack) { onChangePreviousPage([...previousPage, page]); } onChangePage("booking" + groupID); } }>
                <Text style={ styles.blueButtonLabel }>Go to Event Details</Text>
              </TouchableOpacity>
            </View>
          </View>
        );
        HandleBookingResponse(responseJson);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  function SendChatMessage(groupID) {
    // test whether the message is empty
    if( chatMessage.trim().length === 0 ) {
      return;
    }

    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "bookingChat",
        groupID: groupID,
        message: chatMessage
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      scrollInfo.current = {"result":0, "offset":0};
      onChangeChatMessage("");
      HandleChatResponse(responseJson);
      clearTimeout(chatRefreshTimer.current ?? null);
      chatRefreshTimer.current = setTimeout(() => { RefreshChat(groupID) }, CHAT_REFRESH_DELAY);
    }).catch((error) => {
      console.error(error);
    });
  }

  function DeleteChatMessage(groupID, chatID) {
    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "deleteChat",
        groupID: groupID,
        chatID: chatID
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      onChangePopupView("");
      HandleChatResponse(responseJson);
      clearTimeout(chatRefreshTimer.current ?? null);
      chatRefreshTimer.current = setTimeout(() => { RefreshChat(groupID) }, CHAT_REFRESH_DELAY);
    }).catch((error) => {
      console.error(error);
    });
  }

  function ReportChatMessage(groupID, chatID) {
    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "flagChat",
        groupID: groupID,
        chatID: chatID
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      onChangePopupView("");
      HandleChatResponse(responseJson);
      clearTimeout(chatRefreshTimer.current ?? null);
      chatRefreshTimer.current = setTimeout(() => { RefreshChat(groupID) }, CHAT_REFRESH_DELAY);
    }).catch((error) => {
      console.error(error);
    });
  }

  function ShowAttendees(booking) {
    let attending = [], waiting = [];
    booking.attendees.forEach((attendee) => {
      if( attendee.status === "potential" ) {
        if( waiting.length === 0 ) {
          waiting.push({"heading": "Waiting on Response:"});
        }
        waiting.push(attendee);
      } else if( (attendee.status === "accepted") || (attendee.status === "historical") ) {
        if( attending.length === 0 ) {
          attending.push({"heading": "Confirmed:"});
        }
        attending.push(attendee);
      }
    });
    onChangePopupView(
      <View style={ styles.popup }>
        <View style={ styles.popupPanel }></View>
        <View style={ chatHelpPopup.panel }>
          <Text style={ chatHelpPopup.title }>Event Attendees</Text>
          <FlatList style={ [chatHelpPopup.description, chatHelpPopup.scroller] }
                    key="attendeeList"
                    data={ attending.concat(waiting) }
                    numColumns={1}
                    renderItem={({ item,index,separators }) => (ShowAttendeeBlock(item)) }
                    showsVerticalScrollIndicator={false} >
          </FlatList>
          <TouchableOpacity style={ [chatHelpPopup.closeButton, styles.buttonActive] } onPress={() => onChangePopupView("")}>
            <Text style={ styles.whiteButtonLabel }>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  function ShowAttendeeBlock( person ) {
    if( person.heading ) {
      return (
        <View style={ chatPage.attendeeBlock }>
          <View style={ chatPage.headingBox }>
            <Text style={ chatPage.heading }>{ person.heading }</Text>
          </View>
        </View>
      );
    } else {
      return (
        <View style={ chatPage.attendeeBlock }>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoNeutralCircle.svg") }} style={ [chatPage.character, chatPage.attendeeCharacter] } />
          <View style={ chatPage.attendeeNameBox }>
            <Text style={ chatPage.attendeeName }>{ person.isMe ? "You" : person.name }</Text>
          </View>
        </View>
      );
    }
  }

  function ShowCalendarButtons(booking) {
    let d = new Date( parseInt(booking.unixTime) * 1000 );
    let d2 = new Date( (parseInt(booking.unixTime) + parseInt((booking.timeTotal !== undefined) ? booking.timeTotal : 0) * 60) * 1000 );
    let startZulu = String(d.getUTCFullYear()) + ((d.getUTCMonth() < 9) ? "0" : "") + String(d.getUTCMonth() + 1) + ((d.getUTCDate() < 10) ? "0" : "") + String(d.getUTCDate()) + "T" +
                    ((d.getUTCHours() < 10) ? "0" : "") + String(d.getUTCHours()) + ((d.getUTCMinutes() < 10) ? "0" : "") + String(d.getUTCMinutes()) + "00Z";
    let endZulu = String(d2.getUTCFullYear()) + ((d2.getUTCMonth() < 9) ? "0" : "") + String(d2.getUTCMonth() + 1) + ((d2.getUTCDate() < 10) ? "0" : "") + String(d2.getUTCDate()) + "T" +
                  ((d2.getUTCHours() < 10) ? "0" : "") + String(d2.getUTCHours()) + ((d2.getUTCMinutes() < 10) ? "0" : "") + String(d2.getUTCMinutes()) + "00Z";
    let startOutlook = String(d.getUTCFullYear()) + "-" + ((d.getUTCMonth() < 9) ? "0" : "") + String(d.getUTCMonth() + 1) + "-" + ((d.getUTCDate() < 10) ? "0" : "") + String(d.getUTCDate()) +
                       "T" + ((d.getUTCHours() < 10) ? "0" : "") + String(d.getUTCHours()) + ":" + ((d.getUTCMinutes() < 10) ? "0" : "") + String(d.getUTCMinutes()) + ":00+00:00";
    let endOutlook = String(d2.getUTCFullYear()) + "-" + ((d2.getUTCMonth() < 9) ? "0" : "") + String(d2.getUTCMonth() + 1) + "-" + ((d2.getUTCDate() < 10) ? "0" : "") + String(d2.getUTCDate()) +
                     "T" + ((d2.getUTCHours() < 10) ? "0" : "") + String(d2.getUTCHours()) + ":" + ((d2.getUTCMinutes() < 10) ? "0" : "") + String(d2.getUTCMinutes()) + ":00+00:00";
    let addressStr = booking.venue.address.line1 + ", " + booking.venue.address.city + ", " + booking.venue.address.state + " " + booking.venue.address.zip;
    let googleLink = "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=" + startZulu + "%2F" + endZulu + "&details=" + encodeURIComponent(booking.event.description) +
                     "&location=" + encodeURIComponent(addressStr) + "&text=" + encodeURIComponent(booking.event.shortTitle);
    let outlookLink = "https://outlook.office.com/calendar/deeplink/compose?body=" + encodeURIComponent(booking.event.description) + "&enddt=" + encodeURIComponent(endOutlook) +
                      "&location=" + encodeURIComponent(addressStr) + "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=" + encodeURIComponent(startOutlook) + "&subject=" + encodeURIComponent(booking.event.shortTitle);
    onChangePopupView(
      <View style={ styles.popup }>
        <View style={ styles.popupPanel }></View>
        <View style={ chatHelpPopup.panel }>
          <Text style={ chatHelpPopup.title }>Calendar Options</Text>
          <a href={ CALENDAR_BUCKET + "/" + booking.regionID + "/" + booking.sourceID + ".ics" } target="_blank" rel="noopener noreferrer">
            <TouchableOpacity style={ [acceptedPopup.calendarButton, calendarPopup.iCalButton] }>
              <Text style={ [acceptedPopup.calendarButtonLabel, acceptedPopup.iCalLabel] }>Add to iCal</Text>
            </TouchableOpacity>
          </a>
          <a href={ outlookLink } target="_blank" rel="noopener noreferrer">
            <TouchableOpacity style={ [acceptedPopup.calendarButton, calendarPopup.outlookButton] }>
              <Text style={ [acceptedPopup.calendarButtonLabel, acceptedPopup.outlookLabel] }>Add to Outlook Calendar</Text>
            </TouchableOpacity>
          </a>
          <a href={ googleLink } target="_blank" rel="noopener noreferrer">
            <TouchableOpacity style={ [acceptedPopup.calendarButton, calendarPopup.googleButton] }>
              <Text style={ [acceptedPopup.calendarButtonLabel, acceptedPopup.googleLabel] }>Add to Google Calendar</Text>
            </TouchableOpacity>
          </a>
          <TouchableOpacity style={ [calendarPopup.closeButton, styles.buttonActive] } onPress={() => onChangePopupView("")}>
            <Text style={ styles.whiteButtonLabel }>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  function VerifySession(response) {
    if( response.status === 401 ) {
      cookies.remove("bfmsID", { path: '/' } );
      onChangePreviousPage([]);
      onChangePage("login");
      return [];
    } else if( response.status === 404 ) {
      window.location.replace("/");
      return [];
    }
    return response.json()
  }

  function RefreshSession() {
    onChangeProcessingRefresh(true);

    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "refresh"
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      onChangeProcessingRefresh("");
      if( responseJson.success ) {
        FinishLogin(responseJson);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  function RefreshChat(groupID) {
    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "refreshChat",
        groupID: groupID
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      if( responseJson.success ) {
        HandleChatResponse(responseJson);
        ScrollChatList();
      }
      clearTimeout(chatRefreshTimer.current ?? null);
      chatRefreshTimer.current = setTimeout(() => { RefreshChat(groupID) }, CHAT_REFRESH_DELAY);
    }).catch((error) => {
      console.error(error);
    });
  }

  function SubmitQuestionnaire() {
    onChangeEmployeeInfo({...employeeInfo, isSaved:true})

    let question = employeeInfo.questionnaireData[page.substring(13)], answers = [];
    if( question.maxAnswers === 1 ) {
      qRadio.forEach((thisOption) => {
        if( thisOption.selected ) {
          answers.push(thisOption.answerID);
        }
      });
    } else {
      answers = GetMultiselectJSON(qMultiselect);
    }

    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "questionnaire",
        questionID: question.questionID,
        answerID: answers
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      if( responseJson.success ) {
        HandleQuestionnaireResponse(responseJson);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  function SubmitInterests(returnPage) {
    onChangeEmployeeInfo({...employeeInfo, isSaved:true})

    let answers = GetMultiselectJSON(interestsMultiselect);

    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "questionnaire",
        questionID: 0,
        answerID: answers
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      if( responseJson.success ) {
        onChangePopupView(
          <View style={ styles.popup }>
            <View style={ styles.popupPanel }></View>
            <View style={ submitRatingPopup.panel }>
              <Text style={ submitRatingPopup.title }>Thanks!</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoWavingFull.svg") }} accessibilityLabel="Excited" style={ submitRatingPopup.character } />
              <Text style={ submitRatingPopup.description }>
                You'll soon receive custom event invites in your email and on the app, tailored just for you.
                To keep group sizes small and approachable, you'll only be able to attend the events you’re personally invited to.{"\n\n"}
                If you ever need to update your availability or interests, simply head to your Profile page where you can edit your questionnaire.
              </Text>
              <TouchableOpacity style={ [submitRatingPopup.closeButton, styles.buttonActive] } onPress={() => { onChangePopupView(""); onChangePage(returnPage);} }>
                <Text style={ styles.whiteButtonLabel }>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        );
        HandleQuestionnaireResponse(responseJson);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  function PreflightSurvey(groupID) {
    let updatedState = attend.map((radioItem) =>
      ({ ...radioItem, selected: false })
    );
    onSetAttend(updatedState);
    onChangeBookingRating("");
    onChangeGroupRating("");
    onChangeRatingComment("");
    onChangePreviousPage([...previousPage, page]);
    onChangePage("survey" + groupID);
  }

  function SubmitRating(groupID) {
    let attendValue = "";
    attend.forEach((item) => { if( item.selected ) attendValue = item.value} );

    const token = jwtSign(
      {
        "jwtid": cookies.get("bfmsID"),
        "rotatingToken": GenerateRotatingToken()
      },
      deviceID,
      {}
    );

    fetch(MY_DOMAIN_NAME + '/taskHandler.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        subject: "bookingRating",
        groupID: groupID,
        attended: attendValue,
        bookingRating: (attendValue ? bookingRating : 0),
        groupRating: (attendValue ? groupRating : 0),
        comments: ratingComment
      })
    })
    .then((response) => VerifySession(response))
    .then((responseJson) => {
      if( responseJson.success ) {
        onChangePopupView(
          <View style={ styles.popup }>
            <View style={ styles.popupPanel }></View>
            <View style={ submitRatingPopup.panel }>
              <Text style={ submitRatingPopup.title }>Thanks!</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoWavingFull.svg") }} accessibilityLabel="Excited" style={ submitRatingPopup.character } />
              <Text style={ submitRatingPopup.description }>Thanks for the info!{"\n"}I love hearing your feedback{"\n"}on my recommendations!</Text>
              <TouchableOpacity style={ [submitRatingPopup.closeButton, styles.buttonActive] } onPress={() => { onChangePopupView(""); onChangePage("landingPage"); } }>
                <Text style={ styles.whiteButtonLabel }>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        );
        onChangePage(attendValue ? ("booking" + groupID) : "upcomingPage");
        HandleBookingResponse(responseJson);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  function GetBottomNav() {
    return (
      <View style={ styles.bottomNav }>
        <TouchableOpacity style={ [styles.navButton] } onPress={() => onChangePage("landingPage")} >
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((page !== "landingPage") ? "in" : "") + "activeInvite.svg") }} accessibilityLabel="Invites" style={ styles.navButtonIcon } />
          <Text style={ [styles.navButtonCaption, ((page !== "landingPage") ? styles.navButtonCaptionInactive : styles.navButtonCaptionActive)] }>Invites</Text>
        </TouchableOpacity>
        {/* <TouchableOpacity  style={ [styles.navButton] }onPress={() => onChangePage("searchPage")} >
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((page !== "searchPage") ? "in" : "") + "activeSearch.svg") }} accessibilityLabel="Discover" style={ styles.navButtonIcon } />
          <Text style={ [styles.navButtonCaption, ((page !== "searchPage") ? styles.navButtonCaptionInactive : styles.navButtonCaptionActive)] }>Discover</Text>
        </TouchableOpacity> */}
        <TouchableOpacity style={ [styles.navButton] } onPress={() => onChangePage("upcomingPage")} >
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((page !== "upcomingPage") ? "in" : "") + "activeEvent.svg") }} accessibilityLabel="My Events" style={ styles.navButtonIcon } />
          <Text style={ [styles.navButtonCaption, ((page !== "upcomingPage") ? styles.navButtonCaptionInactive : styles.navButtonCaptionActive)] }>My Events</Text>
        </TouchableOpacity>
        <TouchableOpacity style={ [styles.navButton] } onPress={() => onChangePage("profile")} >
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((page !== "profile") ? "in" : "") + "activeProfile.svg") }} accessibilityLabel="Profile" style={ styles.navButtonIcon } />
          <Text style={ [styles.navButtonCaption, ((page !== "profile") ? styles.navButtonCaptionInactive : styles.navButtonCaptionActive)] }>Profile</Text>
        </TouchableOpacity>
      </View>
    );
  }

  const weekdays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  const ends = {1:"st", 2:"nd", 3:"rd", 21:"st", 22:"nd", 23:"rd", 31:"st"};

  function ShowLandscapeEvent(event) {
    return (
      <TouchableOpacity style={ landscapeEvent.panel } onPress={() => { onChangePreviousPage([...previousPage, page]); onChangePage("event" + event.eventID); } } >
        <Image source={{ "uri":event.landscapeImageURL }} style={ landscapeEvent.image } />
        <TouchableOpacity style={ landscapeEvent.likeButtonTouchable } onPress={() => LikeEvent(event.eventID, !event.liked)} >
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + (event.liked ? "" : "in") + "activeLikeIcon.svg") }} accessibilityLabel="Like"
                 style={ landscapeEvent.likeButton } />
        </TouchableOpacity>
        <Text style={ landscapeEvent.title }>{ event.shortTitle }</Text>
      </TouchableOpacity>
    );
  }

  function ShowLandscapeBooking(booking) {
    let d = new Date( parseInt(booking.unixTime) * 1000 );

    return (
      <TouchableOpacity style={ [landscapeEvent.panel, ((booking.status === "accepted") && ((d.getTime() + BOOKING_DELAY_TIME) <= Date.now())) ? landscapeEvent.needsAction : null] }
                        onPress={() => { onChangePreviousPage([...previousPage, page]); onChangePage("booking" + booking.groupID); onChangeBookingRating(0); onChangeGroupRating(0); } } >
        <Image source={{ "uri":booking.event.landscapeImageURL }} style={ landscapeEvent.image } />
        <TouchableOpacity style={ landscapeEvent.likeButtonTouchable } onPress={() => LikeEvent(booking.event.eventID, !booking.event.liked)} >
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + (booking.event.liked ? "" : "in") + "activeLikeIcon.svg") }} accessibilityLabel="Like"
                 style={ landscapeEvent.likeButton } />
        </TouchableOpacity>
        <Text style={ landscapeEvent.title }>{ booking.event.shortTitle }</Text>
        <Text style={ landscapeEvent.date }>{ SafeGet(months,d.getMonth()) + " " + d.getDate() + (ends.hasOwnProperty(d.getDate()) ? SafeGet(ends,d.getDate()) : "th") + " " + d.getFullYear() }</Text>
      </TouchableOpacity>
    );
  }

  function ShowLandscapeBookingBig(booking) {
    let d = new Date( parseInt(booking.unixTime) * 1000 );
    return (
      <View style={ landscapeEvent.containerBig }>
        <TouchableOpacity style={ [landscapeEvent.panelBig, ((booking.status === "accepted") && ((d.getTime() + BOOKING_DELAY_TIME) <= Date.now())) ? landscapeEvent.needsAction : null] }
                          onPress={() => { onChangePreviousPage([...previousPage, page]); onChangePage("booking" + booking.groupID); onChangeBookingRating(0); onChangeGroupRating(0); } } >
          <Image source={{ "uri":booking.event.landscapeImageURL }} style={ landscapeEvent.imageBig } />
          <TouchableOpacity onPress={() => LikeEvent(booking.event.eventID, !booking.event.liked)} >
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + (booking.event.liked ? "" : "in") + "activeLikeIcon.svg") }} accessibilityLabel="Like"
                   style={ landscapeEvent.likeButtonBig } />
          </TouchableOpacity>
          <Text style={ landscapeEvent.titleBig }>{ booking.event.shortTitle }</Text>
          <Text style={ landscapeEvent.dateBig }>{ (page === "searchPage") ? "" : (SafeGet(months,d.getMonth()) + " " + d.getDate() + (ends.hasOwnProperty(d.getDate()) ? SafeGet(ends,d.getDate()) : "th") + " " + d.getFullYear()) }</Text>
        </TouchableOpacity>
        <View style={ landscapeEvent.buttonContainerBig }>
          <TouchableOpacity style={ [landscapeEvent.acceptButton, styles.buttonActive] } onPress={() => AcceptBooking(booking.groupID)}>
            <Text style={ styles.whiteButtonLabel }>Accept</Text>
          </TouchableOpacity>
          <TouchableOpacity style={ landscapeEvent.buttonSpacer }></TouchableOpacity>
          <TouchableOpacity style={ [landscapeEvent.declineButton, styles.whiteButtonActive] } onPress={() => PreflightDeclineBooking(booking.groupID)}>
            <Text style={ styles.blueButtonLabel }>Decline</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  function IsBookingNow(booking) {
    let d = new Date( parseInt(booking.unixTime) * 1000 );
    let d2 = new Date( (parseInt(booking.unixTime) + parseInt(booking.timeTotal) * 60) * 1000 );
    return ((d.getTime() - BOOKING_OPEN_TIME) <= Date.now()) && ((d2.getTime() + BOOKING_DELAY_TIME) > Date.now());
  }

  function IsBookingOver(booking) {
    let d = new Date( (parseInt(booking.unixTime) + parseInt(booking.timeTotal) * 60) * 1000 );
    return ((d.getTime() + BOOKING_DELAY_TIME) <= Date.now());
  }

  function ShowUpcomingBooking(booking) {
    let d = new Date( parseInt(booking.unixTime) * 1000 );
    let d2 = new Date( (parseInt(booking.unixTime) + parseInt(booking.timeTotal) * 60) * 1000 );
    let checkinState = IsBookingNow(booking);
    employeeInfo.myChats.forEach((loopChat) => {
      if( (loopChat.groupID === booking.groupID) && loopChat.isMe && loopChat.isCheckin ) {
        checkinState = false;
      }
    });
    let chatState = IsBookingNow(booking);
    let surveyState = IsBookingOver(booking);
    let isBookingCancelled = booking.status === 'cancelled';
    return (
      isBookingCancelled ? 
        <TouchableOpacity style={ [upcomingPage.panel, upcomingPage.bigPanel] } onPress={() => { 
            onChangePopupView(
              <View style={ styles.popup }>
                <View style={ styles.popupPanel }></View>
                <View style={ declinePopup.panel }>
                  <Text style={ declinePopup.title }>Aw shucks...</Text>
                  <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoSadFull.svg") }} accessibilityLabel="Disappointment" style={ declinePopup.character } />
                  <Text style={ declinePopup.description }>You were invited to this event,{"\n"}but unfortunately it has been cancelled.{"\n"}I'll send you some more event{"\n"}recommendations soon.</Text>
                  <TouchableOpacity style={ [declinePopup.closeButton, styles.buttonActive] } onPress={() => { onChangePopupView(""); onChangePage("upcomingPage"); } }>
                    <Text style={ styles.whiteButtonLabel }>Close</Text>
                  </TouchableOpacity>
                </View>
              </View>
            );} } 
          >
          <View style={ upcomingPage.dateBox }>
            <Text style={ [upcomingPage.monthLabel, cancelledEvent.strikeThrough] }>{ SafeGet(months,d.getMonth()) }</Text>
            <Text style={ [upcomingPage.dateLabel, cancelledEvent.strikeThrough] }>{ d.getDate() }</Text>
            <Text style={ [upcomingPage.yearLabel, cancelledEvent.strikeThrough] }>{ d.getFullYear() }</Text>
          </View>
          <Text style={ [upcomingPage.title, cancelledEvent.strikeThrough] }>{ booking.event.shortTitle }</Text>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/upcomingClock.svg") }} style={ upcomingPage.clock } />
          <Text style={ [upcomingPage.time, cancelledEvent.strikeThrough] }>{ ((((d.getHours() === 0) || (d.getHours() === 12)) ? "12" : (d.getHours() % 12)) + ":" + (((d.getMinutes() < 10) ? "0" : "") + d.getMinutes()) + ((d.getHours() < 12) ? "AM" : "PM")) + " - " +
            ((((d2.getHours() === 0) || (d2.getHours() === 12)) ? "12" : (d2.getHours() % 12)) + ":" + (((d2.getMinutes() < 10) ? "0" : "") + d2.getMinutes()) + ((d2.getHours() < 12) ? "AM" : "PM")) }</Text>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/upcomingLocation.svg") }} style={ upcomingPage.location } />
          <Text style={ [upcomingPage.address, cancelledEvent.strikeThrough] }>{ booking.venue.address.line1 + "\n" + (booking.venue.address.line2 ? (booking.venue.address.line2 + "\n" ) : "") + booking.venue.address.city + ", " + booking.venue.address.state + " " + booking.venue.address.zip }</Text>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/upcomingArrow.svg") }} style={ upcomingPage.arrow } />
          <TouchableOpacity disabled={ true } style={ [upcomingPage.actionButton, styles.buttonActive, cancelledEvent.cancelledButton] }>
            <Text style={ styles.whiteButtonLabel }>Event Cancelled</Text>
          </TouchableOpacity>
        </TouchableOpacity>
      :
        <TouchableOpacity style={ [upcomingPage.panel, ((checkinState || chatState || surveyState) ? upcomingPage.bigPanel : null)] } onPress={() => { onChangePreviousPage([...previousPage, page]); onChangePage("booking" + booking.groupID); } } >
          <View style={ upcomingPage.dateBox }>
            <Text style={ upcomingPage.monthLabel }>{ SafeGet(months,d.getMonth()) }</Text>
            <Text style={ upcomingPage.dateLabel }>{ d.getDate() }</Text>
            <Text style={ upcomingPage.yearLabel }>{ d.getFullYear() }</Text>
          </View>
          <Text style={ upcomingPage.title }>{ booking.event.shortTitle }</Text>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/upcomingClock.svg") }} style={ upcomingPage.clock } />
          <Text style={ upcomingPage.time }>{ ((((d.getHours() === 0) || (d.getHours() === 12)) ? "12" : (d.getHours() % 12)) + ":" + (((d.getMinutes() < 10) ? "0" : "") + d.getMinutes()) + ((d.getHours() < 12) ? "AM" : "PM")) + " - " +
            ((((d2.getHours() === 0) || (d2.getHours() === 12)) ? "12" : (d2.getHours() % 12)) + ":" + (((d2.getMinutes() < 10) ? "0" : "") + d2.getMinutes()) + ((d2.getHours() < 12) ? "AM" : "PM")) }</Text>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/upcomingLocation.svg") }} style={ upcomingPage.location } />
          <Text style={ upcomingPage.address }>{ booking.venue.address.line1 + "\n" + (booking.venue.address.line2 ? (booking.venue.address.line2 + "\n" ) : "") + booking.venue.address.city + ", " + booking.venue.address.state + " " + booking.venue.address.zip }</Text>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/upcomingArrow.svg") }} style={ upcomingPage.arrow } />
          { checkinState
            ? <TouchableOpacity style={ [upcomingPage.actionButton, styles.buttonActive] } onPress={() => BookingCheckin(booking.groupID, booking.meetingSpot, true)}>
                <Text style={ styles.whiteButtonLabel }>Check-In</Text>
              </TouchableOpacity>
            : (chatState
              ? <TouchableOpacity style={ [upcomingPage.actionButton, styles.buttonActive] } onPress={() => { scrollInfo.current = {"offset":0, "result":0}; onChangePreviousPage([...previousPage, page]); onChangePage("chat" + booking.groupID); RefreshChat(booking.groupID);}}>
                  <Text style={ styles.whiteButtonLabel }>Chat</Text>
                </TouchableOpacity>
              : (surveyState
                ? <TouchableOpacity style={ [upcomingPage.actionButton, styles.buttonActive] } onPress={() => PreflightSurvey(booking.groupID) }>
                    <Text style={ styles.whiteButtonLabel }>Post-Event Survey</Text>
                  </TouchableOpacity>
                : null))
          }
        </TouchableOpacity>
    );
  }

  function ShowChat(chat) {
    if( chat.meetingHeading ) {
      return (
	    <View>
          <View style={ chatPage.chatBlock }>
            <Text style={ chatPage.locationLabel }>{ "Meeting Location: " + chat.meetingSpot }</Text>
          </View>
          <View style={ chatPage.chatBlock }>
            <View style={ chatPage.checkinStrip }></View>
            <View style={ chatPage.checkinLabel }><Text style={ chatPage.checkinLabelText }>A message from Coco your Concierge</Text></View>
          </View>
          <View>
            <View style={ chatPage.chatBlock }>
              <Text style={ chatPage.chatMessageLabel }>Hello! Wait for your peers by the meeting location listed above. If attendees haven't arrived within the 15 minute grace period, you may begin your activity. Enjoy!</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoNeutralCircle.svg") }} style={ chatPage.character } />
            </View>
          </View>
        </View>
      );
    }
    let d = new Date( parseInt(chat.unixTime) * 1000 );
    let timeStr = ((((d.getHours() === 0) || (d.getHours() === 12)) ? "12" : (d.getHours() % 12)) + ":" + (((d.getMinutes() < 10) ? "0" : "") + d.getMinutes()) + ((d.getHours() < 12) ? "AM" : "PM"));
    if( chat.isCheckin ) {
      return (
        <View style={ chatPage.chatBlock }>
          <View style={ chatPage.checkinStrip }></View>
          <View style={ chatPage.checkinLabel }><Text style={ chatPage.checkinLabelText }>{ (chat.isMe ? "You" : chat.employeeName) + " checked in at " + timeStr + "." }</Text></View>
        </View>
      );
    } else {
      return (
        <View>
          { (chat.senderID < 0)
            ? null
            : <View style={ chatPage.speakerBlock }>
                <Text style={ [chatPage.speakerLabel, (chat.isMe ? chatPage.myLabels : null)] }>{ (chat.isMe ? "You" : chat.employeeName) + " @" + timeStr }</Text>
              </View>
          }
          <Pressable style={ chatPage.chatBlock } onLongPress={(e) => ShowChatOptions(e, chat) }>
            <Text style={ [chatPage.chatMessageLabel, (chat.isMe ? chatPage.myChatMessageLabel : null)] }>{ chat.message }</Text>
            { (chat.senderID === -1) ? null : <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoNeutralCircle.svg") }} style={ [chatPage.character, (chat.isMe ? chatPage.myCharacter : null)] } /> }
          </Pressable>
        </View>
      );
    }
  }

  function ShowChatOptions(e, chat) {
    onChangePopupView(
      <View style={ styles.popup }>
        <View style={ styles.popupPanel }></View>
        <View style={ [chatPage.chatBlock, {"position":"absolute", "top":(e.currentTarget.getBoundingClientRect().top + window.scrollY - 5)}] }>
          <Text style={ [chatPage.chatMessageLabel, (chat.isMe ? chatPage.myChatMessageLabel : null)] }>{ chat.message }</Text>
          { (chat.senderID === -1) ? null : <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoNeutralCircle.svg") }} style={ [chatPage.character, (chat.isMe ? chatPage.myCharacter : null)] } /> }
          { (chat.isMe
            ? <TouchableOpacity style={ [chatPage.messageButton, chatPage.messageButtonTop, (chat.isMe ? chatPage.myChatMessageButton : null)] } onPress={() => onChangePopupView(
                  <View style={ styles.popup }>
                    <View style={ styles.popupPanel }></View>
                    <View style={ confirmDeclinePopup.panel }>
                      <Text style={ confirmDeclinePopup.title }>Are you sure?</Text>
                      <Text style={ confirmDeclinePopup.description }>This message will be deleted forever.</Text>
                      <TouchableOpacity style={ [confirmDeclinePopup.cancelButton, styles.whiteButtonActive] } onPress={() => onChangePopupView("")}>
                        <Text style={ styles.blueButtonLabel }>Cancel</Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={ [confirmDeclinePopup.confirmButton, styles.buttonActive] } onPress={() => DeleteChatMessage(chat.groupID, chat.chatID)}>
                        <Text style={ styles.whiteButtonLabel }>Confirm</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}>
                <Text>Delete</Text>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/deleteMessage.svg") }} style={ chatPage.messageButtonIcon } />
              </TouchableOpacity>
          : <TouchableOpacity style={ [chatPage.messageButton, chatPage.messageButtonTop, (chat.isMe ? chatPage.myChatMessageButton : null)] } onPress={() => onChangePopupView(
                <View style={ styles.popup }>
                  <View style={ styles.popupPanel }></View>
                  <View style={ reportChatPopup.panel }>
                    <Text style={ confirmDeclinePopup.title }>Are you sure?</Text>
                    <Text style={ confirmDeclinePopup.description }>This message will be removed and reported as inappropriate to your employer.</Text>
                    <TouchableOpacity style={ [reportChatPopup.cancelButton, styles.whiteButtonActive] } onPress={() => onChangePopupView("")}>
                      <Text style={ styles.blueButtonLabel }>Cancel</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={ [reportChatPopup.confirmButton, styles.buttonActive] } onPress={() => ReportChatMessage(chat.groupID, chat.chatID)}>
                      <Text style={ styles.whiteButtonLabel }>Confirm</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}>
              <Text>Report</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/reportMessage.svg") }} style={ chatPage.messageButtonIcon } />
            </TouchableOpacity>)
          }
          <TouchableOpacity style={ [chatPage.messageButton, chatPage.messageButtonBottom, (chat.isMe ? chatPage.myChatMessageButton : null)] } onPress={() => onChangePopupView("")}>
            <Text>Cancel</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  function ScrollChatList() {
    if( scrollInfo.current.result < 0 ) {
      flatListRef?.current?.scrollToOffset({"offset":scrollInfo.current.offset, "animated":false});
    } else {
      flatListRef?.current?.scrollToEnd({"animated":false});
    }
  }

  const LandscapeBookingSeparator = () => (
    <View style={ landscapeEvent.separator }></View>
  );

  // check to see whether they're trying to get to the reset password page
  const queryParams = new URLSearchParams(window.location.search);
  if( (page !== "resetPassword") && queryParams.get('resetCode') && queryParams.get('customer') && (BASE_CUSTOMER_DOMAIN_NAME !== "") ) {
    onChangePage('resetPassword');
    onChangeMyDomainName( BASE_CUSTOMER_DOMAIN_NAME + '/' + queryParams.get('customer') );
    fetch(BASE_CUSTOMER_DOMAIN_NAME + '/' + queryParams.get('customer') + '/forgotPassword.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({task: 'initialize', resetHash: queryParams.get('resetCode').substring(0,64), employeeID: queryParams.get('resetCode').substring(64), "rotatingToken": GenerateRotatingToken() })
    }).then((response) => VerifySession(response)).then((responseJson) => {
      if( responseJson.success ) {
        onChangeEmployeeInfo({ firstName: responseJson.firstName, lastName: responseJson.lastName });
        onChangeEmail( responseJson.email );
      } else {
        window.location.replace("/");
      }
    }).catch((error) => {
      console.error(error);
    });
  // check to see whether they're trying to get to the claim account page
  } else if( (page !== "claimAccount") && queryParams.get('referral') && queryParams.get('customer') && (BASE_CUSTOMER_DOMAIN_NAME !== "") ) {
    onChangePage('claimAccount');
    onChangeMyDomainName( BASE_CUSTOMER_DOMAIN_NAME + '/' + queryParams.get('customer') );
    fetch(BASE_CUSTOMER_DOMAIN_NAME + '/' + queryParams.get('customer') + '/claimAccount.php', {
      method:'POST',
      credentials:'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({task: 'initialize', claimHash: queryParams.get('referral').substring(0,64), employeeID: queryParams.get('referral').substring(64) })
    }).then((response) => VerifySession(response)).then((responseJson) => {
      if( responseJson.success ) {
        onChangeEmployeeInfo({ firstName: responseJson.firstName, lastName: responseJson.lastName });
        onChangeEmail( responseJson.email );
      } else {
        window.location.replace("/");
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  // make sure they have data for the event they're trying to view
  let thisEvent = null;
  let thisBooking = null;
  if( page.substr(0,5) === "event" ) {
    employeeInfo.myLikedEvents.forEach((loopEvent) => {
      if( loopEvent.eventID === parseInt(page.substr(5)) ) {
        thisEvent = loopEvent;
      }
    });
    employeeInfo.eventMatches.forEach((loopEvent) => {
      if( loopEvent.eventID === parseInt(page.substr(5)) ) {
        thisEvent = loopEvent;
      }
    });
    if( thisEvent === null ) {
      onChangePage("landingPage");
      return "";
    }
  } else if( page.substr(0,7) === "booking" ) {
    employeeInfo.myBookingHistory.forEach((loopBooking) => {
      if( loopBooking.groupID === parseInt(page.substr(7)) ) {
        thisBooking = loopBooking;
      }
    });
    employeeInfo.bookingMatches.forEach((loopBooking) => {
      if( loopBooking.groupID === parseInt(page.substr(7)) ) {
        thisBooking = loopBooking;
      }
    });
    employeeInfo.myAcceptedBookings.forEach((loopBooking) => {
      if( loopBooking.groupID === parseInt(page.substr(7)) ) {
        thisBooking = loopBooking;
      }
    });
    if( thisBooking === null ) {
      onChangePage("landingPage");
      return "";
    }
  } else if( page.substr(0,6) === "survey" ) {
    employeeInfo.myAcceptedBookings.forEach((loopBooking) => {
      if( loopBooking.groupID === parseInt(page.substr(6)) ) {
        thisBooking = loopBooking;
      }
    });
    if( thisBooking === null ) {
      onChangePage("landingPage");
      return "";
    }
  } else if( page.substr(0,4) === "chat" ) {
    employeeInfo.myAcceptedBookings.forEach((loopBooking) => {
      if( loopBooking.groupID === parseInt(page.substr(4)) ) {
        thisBooking = loopBooking;
      }
    });
    if( thisBooking === null ) {
      onChangePage("landingPage");
      return "";
    }
  }

  if (APP_ASSETS_BUCKET === '') {
    return;
  } else if (page === 'meetCoco') {
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Text style={ questionnaire.characterLabel }>Meet Coco the Concierge</Text>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoWavingFull.svg") }} style={ questionnaire.character } />
          <Text style={ questionnaire.explainLabel }>
            Hi there! I’m Coco, your concierge, and I’m here to guide you through a quick 7-question survey to learn about your interests and availability.{"\n\n"}
            Based on your answers, I’ll invite you to small group events with colleagues that match your preferences. 
            With CoExperiences, you won’t have to scroll through an endless list of events—I’ll do the work for you! 
            If you’re into outdoor activities, I might invite you to go kayaking; if you love art, perhaps a pottery class will be perfect for you.{"\n\n"}
            Let’s get started so I can find events that are just right for you!
          </Text>
          <TouchableOpacity style={ [questionnaire.fullNextButton, styles.buttonActive] } onPress={() => onChangePage(nextQuestionnairePage)} >
            <Text style={ styles.whiteButtonLabel }>Next</Text>
          </TouchableOpacity>
          <TouchableOpacity style={ [questionnaire.backButton, styles.whiteButtonActive] } onPress={SubmitLogout}>
            <Text style={ styles.blueButtonLabel } accessibilityLabel="Log Out">Log Out</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  } else if( (page.substr(0,13) === "questionnaire") && (employeeInfo !== "") ) {
    let thisPage = parseInt(page.substr(13)), currentQ = employeeInfo.questionnaireData[thisPage], count = 0, totalQuestions = employeeInfo.questionnaireData.length + 1;
    if( currentQ.maxAnswers === 1 ) {
      qRadio.forEach((thisOption) => {
        count += thisOption.selected ? 1 : 0;
      });
    } else {
      qMultiselect.forEach((thisOption) => {
        count += thisOption.selected ? 1 : 0;
      });
    }
    let disabled = (count < currentQ.minAnswers), skinny = currentQ.answers.length > 6, nextPage = (thisPage < (employeeInfo.questionnaireData.length - 1)) ? ("questionnaire" + (thisPage + 1)) : "interests";
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Text style={ questionnaire.progressLabel }>Question { thisPage + 1 }/{ totalQuestions }</Text>
          <View style={ questionnaire.dynamicBox }>
            { currentQ.superCaption ? <Text style={ questionnaire.superCaptionLabel }>{ currentQ.superCaption }</Text> : null }
            <Text style={ questionnaire.questionLabel }>{ currentQ.caption }</Text>
            { currentQ.note ? <Text style={ questionnaire.noteLabel }>{ currentQ.note }</Text> : null }
            { currentQ.footnote ? <Text style={ questionnaire.footnoteLabel }>{ currentQ.footnote }</Text> : null }
            <View style={ questionnaire.optionBox }>
              { (currentQ.maxAnswers === 1)
                ? qRadio.map((item) => (
                  <View style={ [questionnaire.optionButton, (skinny ? questionnaire.optionButtonSkinny : null)] }>
                    <RadioButton onPress={() => { onChangeEmployeeInfo({...employeeInfo, isSaved:false}); onRadioBtnClick(item, qRadio, onSetQRadio); }} selected={item.selected} skinny={ skinny } survey={false} key={item.answerID} >
                      {item.caption}
                    </RadioButton>
                  </View>
                ))
                : qMultiselect.map((item) => (
                  <View style={ [questionnaire.optionButton, (skinny ? questionnaire.optionButtonSkinny : null)] }>
                    <CheckboxButton onPress={() => { onChangeEmployeeInfo({...employeeInfo, isSaved:false}); onMultiselectClick(item, qMultiselect, !item.selected, onSetQMultiselect); }} selected={item.selected} skinny={ skinny } key={item.answerID} >
                      {item.caption}
                    </CheckboxButton>
                  </View>
                ))
              }
          </View>
        </View>
          { disabled
            ? <Text style={ [questionnaire.warningLabel] }>You must choose at least { currentQ.minAnswers } option{ (currentQ.minAnswers !== 1) ? "s" : "" } to exit this page</Text>
            : null
          }
          <TouchableOpacity style={ [questionnaire.previousButton, ((thisPage > 0) ? styles.buttonActive : styles.buttonInactive)] } onPress={() => { PrimeQuestionnaire(employeeInfo.questionnaireData[thisPage - 1]); onChangePage("questionnaire" + (thisPage - 1)); }} disabled={ (thisPage > 0) ? false : true }>
            <Text style={ styles.whiteButtonLabel }>Previous</Text>
          </TouchableOpacity>
          <TouchableOpacity style={ [questionnaire.nextButton, (disabled ? styles.buttonInactive : styles.buttonActive)] } onPress={() => { SubmitQuestionnaire(); if( nextPage !== "interests" ) { PrimeQuestionnaire(employeeInfo.questionnaireData[thisPage + 1]); } onChangePage(nextPage); }} disabled={ disabled }>
            <Text style={ styles.whiteButtonLabel }>Next</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  } else if( (page === "interests") && (employeeInfo !== "") ) {
    let count = 0, prevPage = employeeInfo.questionnaireData.length - 1, interestMap = [], totalQuestions = employeeInfo.questionnaireData.length + 1;
    interestsMultiselect.forEach((thisOption) => {
      count += thisOption.selected ? 1 : 0;
      interestMap[thisOption.interestID] = thisOption;
    });
    let disabled = (count < employeeInfo.interestData.minAnswers), returnPage = (employeeInfo.interestData.myResponse.length === 0 ? "landingPage" : "profile");
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <ScrollView style={ interests.interestBox }>
            <Text style={ interests.progressLabel }>Question { totalQuestions }/{ totalQuestions }</Text>
            <Text style={ interests.interestHeaderLabel }>Now for the fun part!{"\n"}Please select the event categories that interest you.</Text>
            { employeeInfo.interestData.categories.map((thisCategory) => (
                <View style={ interests.categoryBlock }>
                  { (thisCategory === employeeInfo.interestData.categories[0]) ? null : <View style={ interests.dividerLine }></View> }
                  <Text style={ interests.categoryLabel }>{ thisCategory.name }</Text>
                  { thisCategory.interests.map((item) => (
                    <InterestButton onPress={() => { onChangeEmployeeInfo({...employeeInfo, isSaved:false}); onInterestBtnClick(item, interestsMultiselect, !interestMap[item.interestID].selected, onSetInterestsMultiselect); }} selected={ interestMap[item.interestID].selected } key={item.interestID} >
                      {item}
                    </InterestButton>
                    ))
                  }
                  <View style={ interests.airGap }></View>
                </View>
              ))
            }
            <Text style={ interests.warningLabel }>{ disabled ? ("You must choose at least " + employeeInfo.interestData.minAnswers + " option" + ((employeeInfo.interestData.minAnswers !== 1) ? "s" : "") + " to exit this page") : "" }</Text>
            <View style={ interests.navButtonBox }>
              <TouchableOpacity style={ [interests.previousButton, styles.buttonActive] } onPress={() => { PrimeQuestionnaire(employeeInfo.questionnaireData[prevPage]); onChangePage("questionnaire" + prevPage); }}>
                <Text style={ styles.whiteButtonLabel }>Previous</Text>
              </TouchableOpacity>
              <TouchableOpacity style={ [interests.nextButton, (disabled ? styles.buttonInactive : styles.buttonActive)] } onPress={() => { SubmitInterests(returnPage); onChangePage(returnPage);}} disabled={ disabled }>
                <Text style={ styles.whiteButtonLabel }>All Done!</Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </View>
      </View>
    );
  } else if( page === "hidden" ) {
    let TARGET_LENGTH = (employeeInfo.bookingMatches.length === 0) ? 4 : 2;
    let likedList = [];
    employeeInfo.myLikedEvents.forEach((loopEvent) => {
      if( likedList.length < TARGET_LENGTH ) {
        likedList.push(loopEvent);
      }
    });
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Text style={ homePage.welcomeLabel }>Welcome Back, { employeeInfo.firstName }!</Text>
          <View style={ [homePage.dividerLine, homePage.firstDivider ] }></View>
          <Text style={ [homePage.label, homePage.inviteLabel] }>Event Invitations</Text>
          { (employeeInfo.bookingMatches.length === 0)
            ? <View style={ [homePage.emptyInvitesList, homePage.emptyInvitesListLarge] }>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoWavingCircle.svg") }} accessibilityLabel="No Invites Yet" style={ [styles.characterImage, homePage.characterImage] } />
                <Text style={ styles.characterLabel }>
                  You don’t have any event invites just yet. I’m busy searching through my event catalog to find the perfect match for you. 
                  I’ll notify you via email as soon as I find the right one—stay tuned!
                </Text>
              </View>
            : <View>
                { (employeeInfo.bookingMatches.length > 1) ? <Text style={ [homePage.viewAllLink, homePage.viewAllInvites] }><TouchableOpacity onPress={() => onChangePage("invites")}>View All &gt;</TouchableOpacity></Text> : null }
                <View style={ homePage.inviteEvent } >{ ShowLandscapeBookingBig( employeeInfo.bookingMatches[0] ) }</View>
              </View>
          }
          <View style={ [homePage.dividerLine, ((TARGET_LENGTH === 2) ? homePage.secondDivider2 : homePage.secondDivider4) ] }></View>
          <Text style={ [homePage.label, ((TARGET_LENGTH === 2) ? homePage.likedLabel2 : homePage.likedLabel4)] }>Liked Events</Text>
          { (likedList.length === 0)
            ? <View style={ [homePage.emptyInvitesList, ((TARGET_LENGTH === 2) ? homePage.emptyLikesList2 : homePage.emptyLikesList4)] }>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoLikeSignCircle.svg") }} accessibilityLabel="No Likes Yet" style={ styles.characterImage } />
                <Text style={ styles.characterLabelLong }>You have not liked any events. Liking events will help me better understand what events you would like to be invited to.</Text>
              </View>
			: <View>
                { (employeeInfo.myLikedEvents.length > TARGET_LENGTH)
                  ? <Text style={ [homePage.viewAllLink, ((TARGET_LENGTH === 2) ? homePage.viewAll2 : homePage.viewAll4)] }><TouchableOpacity onPress={() => onChangePage("likedEvents")}>View All &gt;</TouchableOpacity></Text>
                  : null
                }
                <FlatList style={ [homePage.likedList, ((TARGET_LENGTH === 2) ? homePage.likedList2 : homePage.likedList4)] }
                          key="eventsList"
                          data={ likedList }
                          numColumns={2}
                          ItemSeparatorComponent={ LandscapeBookingSeparator }
                          renderItem={({ item,index,separators }) => (ShowLandscapeEvent(item)) }
                          showsVerticalScrollIndicator={false} >
                </FlatList>
              </View>
          }
          { GetBottomNav() }
          { popupView }
        </View>
      </View>
    );
  } else if( page === "likedEvents" ) {
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Text style={ styles.pageLabel }>Liked Events</Text>
          <View style={ likedEventsPage.infoBox }>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoLikeSignCircle.svg") }} accessibilityLabel="Liked Events" style={ styles.characterImage } />
            <Text style={ styles.characterLabel }>These are all of the events you told me that you liked. I hope you continue adding to the list!</Text>
          </View>
          <FlatList style={ likedEventsPage.list }
                    key="eventsList"
                    data={ employeeInfo.myLikedEvents }
                    numColumns={2}
                    ItemSeparatorComponent={ LandscapeBookingSeparator }
                    renderItem={({ item,index,separators }) => (ShowLandscapeEvent(item)) }
                    showsVerticalScrollIndicator={false} >
          </FlatList>
          { GetBottomNav() }
          { popupView }
        </View>
      </View>
    );
  } else if( page === "landingPage" ) {
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Text style={ styles.pageLabel }>Event Invitations</Text>
          { (employeeInfo.bookingMatches.length === 0)
            ? <View style={ [likedEventsPage.infoBox, homePage.emptyInvitesList, homePage.emptyInvitesListLarge] }>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoWavingCircle.svg") }} accessibilityLabel="No Invites Yet" style={ [styles.characterImage, homePage.characterImage] } />
                <Text style={ styles.characterLabel }>You don’t have any event invites just yet. I’m busy searching through my event catalog to find the perfect match for you. 
                I’ll notify you via email as soon as I find the right one—stay tuned!</Text>
              </View>
            : <View style={ likedEventsPage.infoBox }>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoNeutralCircle.svg") }} accessibilityLabel="Event Invitations" style={ styles.characterImage } />
                <Text style={ styles.characterLabel }>These are all of the events I've invited you to. I hope you'll think they're as much fun as I do!</Text>
              </View>
          }
          <FlatList style={ likedEventsPage.list }
                    key="invitesList"
                    data={ employeeInfo.bookingMatches }
                    ItemSeparatorComponent={ LandscapeBookingSeparator }
                    renderItem={({ item,index,separators }) => (ShowLandscapeBookingBig(item)) }
                    showsVerticalScrollIndicator="false" >
          </FlatList>
          { GetBottomNav() }
          { popupView }
        </View>
      </View>
    );
  } else if( page === "searchPage" ) {
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Text style={ styles.pageLabel }>Discover</Text>
          <View style={ searchPage.infoBox }>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoConfusedCircle.svg") }} accessibilityLabel="Events" style={ searchPage.characterImage } />
            <View style={ searchPage.textBox }>
              <Text style={ searchPage.characterLabel }>Don't know what events you like? Check out some of the events below! Tap on the heart to like an event.</Text>
            </View>
          </View>
          { (employeeInfo.eventMatches.length === 0)
            ? <View style={ [homePage.emptyInvitesList, {"border":0}] }>
                <Text style={ homePage.noEventsLabel }>No events at the moment. Please check{"\n"}back later!</Text>
              </View>
            : <FlatList style={ searchPage.list }
                        key="eventsList"
                        data={ employeeInfo.eventMatches }
                        numColumns={2}
                        renderItem={({ item,index,separators }) => (ShowLandscapeEvent(item)) } 
                        showsVerticalScrollIndicator={false} >
              </FlatList>
          }
          { GetBottomNav() }
          { popupView }
        </View>
      </View>
    );
  } else if( page === "upcomingPage" ) {
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Text style={ styles.pageLabel }>My Events</Text>
          { (employeeInfo.myAcceptedBookings.length === 0)
            ? <View style={ [likedEventsPage.infoBox, upcomingPage.emptyInvitesList] }>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoNeutralCircle.svg") }} accessibilityLabel="No Events Yet" style={ styles.characterImage } />
                <Text style={ styles.characterLabel }>Once you receive an event invite and accept, you’ll see it listed here on your My Events page!</Text>
              </View>
            : <FlatList style={ upcomingPage.list }
                        key="acceptedList"
                        data={ employeeInfo.myAcceptedBookings }
                        ItemSeparatorComponent={ LandscapeBookingSeparator }
                        renderItem={({ item,index,separators }) => (ShowUpcomingBooking(item)) }
                        showsVerticalScrollIndicator="false" >
              </FlatList>
          }
          { GetBottomNav() }
          { popupView }
        </View>
      </View>
    );
  } else if( page === "pastBookingsPage" ) {
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <TouchableOpacity style={ [styles.backButton, styles.roundButton] } onPress={() => { onChangePage(SafeGet(previousPage,previousPage.length - 1)); onChangePreviousPage(previousPage.slice(0, -1)); }}>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/backArrow.svg") }} style={ detailsPage.backButtonIcon } />
          </TouchableOpacity>
          <Text style={ [styles.pageLabel, styles.rewindable] }>Past Events</Text>
          <View style={ likedEventsPage.infoBox }>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoExcitedCircle.svg") }} accessibilityLabel="Past Events" style={ styles.characterImage } />
            { (employeeInfo.myBookingHistory.length === 0) ?
              <Text style={ styles.characterLabel }>You have not attended any events yet. Please check back after you do!</Text>
              : 
              <Text style={ styles.characterLabel }>Here are all of the events you have attended. I hope these bring back some good memories!</Text>  
            }
          </View>
          <FlatList style={ likedEventsPage.list }
                    key="historyList"
                    data={ employeeInfo.myBookingHistory }
                    numColumns="2"
                    renderItem={({ item,index,separators }) => (ShowLandscapeBooking(item)) } 
                    showsVerticalScrollIndicator="false" >
          </FlatList>
          { GetBottomNav() }
          { popupView }
        </View>
      </View>
    );
  } else if( page === "profile" ) {
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Text style={ profilePage.name }>{ employeeInfo.firstName + " " + employeeInfo.lastName }</Text>
          <Text style={ profilePage.joinDate }>Joined { employeeInfo.joinDate }</Text>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoNeutralCircle.svg") }} style={ profilePage.characterImage } />
          <Text style={ profilePage.settingsHeading }>Account Settings</Text>
          <View style={ profilePage.settingsBox }>
            <TouchableOpacity onPress={() => { PrimeQuestionnaire(employeeInfo.questionnaireData[0]); onChangePage("questionnaire0"); }} style={ profilePage.settingsButton } >
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/questionnaire.svg") }} style={ profilePage.settingsIcon } />
              <Text style={ profilePage.settingsLabel } >Questionnaire</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/settingsArrow.svg") }} style={ profilePage.settingsArrow } />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { onChangePreviousPage([page]); onChangePage("pastBookingsPage")}} style={ profilePage.settingsButton } >
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/eventHistory.svg") }} style={ profilePage.settingsIcon } />
              <Text style={ profilePage.settingsLabel } >Past Events</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/settingsArrow.svg") }} style={ profilePage.settingsArrow } />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { onChangePreviousPage([page]); onChangePage("privacyPage")}} style={ profilePage.settingsButton } >
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/security.svg") }} style={ profilePage.settingsIcon } />
              <Text style={ profilePage.settingsLabel } >Policies</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/settingsArrow.svg") }} style={ profilePage.settingsArrow } />
            </TouchableOpacity>
            <a href="https://coexperiences.com/how-it-works/" target="_blank" rel="noopener noreferrer">
              <TouchableOpacity style={ profilePage.settingsButton } >
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/help.svg") }} style={ profilePage.settingsIcon } />
                <Text style={ profilePage.settingsLabel } >How It Works</Text>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/settingsArrow.svg") }} style={ profilePage.settingsArrow } />
              </TouchableOpacity>
            </a>
            <TouchableOpacity onPress={() => { onChangePreviousPage([page]); onChangePage("helpPage")}} style={ profilePage.settingsButton } >
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/infoCircle.svg") }} style={ profilePage.settingsIcon } />
              <Text style={ profilePage.settingsLabel } >Help</Text>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/settingsArrow.svg") }} style={ profilePage.settingsArrow } />
            </TouchableOpacity>
          </View>
          <TouchableOpacity onPress={SubmitLogout} style={ profilePage.logout } >
            <Text style={ profilePage.logoutCaption } >Log Out</Text>
          </TouchableOpacity>
          { GetBottomNav() }
          { popupView }
        </View>
      </View>
    );
  } else if( page === "privacyPage" ) {
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <TouchableOpacity style={ [styles.backButton, styles.roundButton] } onPress={() => { onChangePage(SafeGet(previousPage,previousPage.length - 1)); onChangePreviousPage(previousPage.slice(0, -1)); }}>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/backArrow.svg") }} style={ detailsPage.backButtonIcon } />
          </TouchableOpacity>
          <Text style={ [styles.pageLabel, styles.rewindable] }>Links</Text>
          <View style={ profilePage.privacyBox }>
            <View style={ profilePage.settingsButton } >
              <a href="https://coexperiences.com/terms-of-service" target="_blank" rel="noopener noreferrer">
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/infoCircle.svg") }} style={ profilePage.settingsIcon } />
                <Text style={ profilePage.settingsLabel } >Terms of Service</Text>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/settingsArrow.svg") }} style={ profilePage.settingsArrow } />
              </a>
            </View>
            <View style={ profilePage.settingsButton } >
              <a href="https://coexperiences.com/privacy" target="_blank" rel="noopener noreferrer">
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/activeReview.svg") }} style={ profilePage.settingsIcon } />
                <Text style={ profilePage.settingsLabel } >Privacy Policy</Text>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/settingsArrow.svg") }} style={ profilePage.settingsArrow } />
              </a>
            </View>
            <View style={ profilePage.settingsButton } >
              <a href="https://coexperiences.com/terms-of-service/#event-conduct" target="_blank" rel="noopener noreferrer">
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/activeCheckin.svg") }} style={ profilePage.settingsIcon } />
                <Text style={ profilePage.settingsLabel } >Conduct Policy</Text>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/settingsArrow.svg") }} style={ profilePage.settingsArrow } />
              </a>
            </View>
            <View style={ profilePage.settingsButton } >
              <a href="https://coexperiences.com/terms-of-service/#no-show-policy" target="_blank" rel="noopener noreferrer">
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/activeCancel.svg") }} style={ profilePage.settingsIcon } />
                <Text style={ profilePage.settingsLabel } >Cancellation Policy</Text>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/settingsArrow.svg") }} style={ profilePage.settingsArrow } />
              </a>
            </View>
            <View style={ profilePage.settingsButton } >
              <a href="https://coexperiences.com/security" target="_blank" rel="noopener noreferrer">
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/security.svg") }} style={ profilePage.settingsIcon } />
                <Text style={ profilePage.settingsLabel } >Security Statement</Text>
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/settingsArrow.svg") }} style={ profilePage.settingsArrow } />
              </a>
            </View>
          </View>
          { GetBottomNav() }
        </View>
      </View>
    );
  } else if( page === "helpPage" ) {
    let disabled = ((helpSubject === "") || (helpDetails === ""));
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <TouchableOpacity style={ [styles.backButton, styles.roundButton] } onPress={() => { onChangePage(SafeGet(previousPage,previousPage.length - 1)); onChangePreviousPage(previousPage.slice(0, -1)); }}>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/backArrow.svg") }} style={ detailsPage.backButtonIcon } />
          </TouchableOpacity>
          <Text style={ [styles.pageLabel, styles.rewindable] }>Help/Support</Text>
          <View style={ searchPage.infoBox }>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoNeutralCircle.svg") }} accessibilityLabel="Support" style={ searchPage.characterImage } />
            <View style={ searchPage.textBox }>
              <Text style={ searchPage.characterLabel }>Oh no! Tell me about the issues you are experiencing.</Text>
            </View>
          </View>
          <Text style={ [helpPage.label, helpPage.emailLabel] }>Your Email</Text>
          <View style={ [loginPage.inputBox, helpPage.emailBox] }>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/email.svg") }} style={ [styles.smallIcon, helpPage.inputIcon] } accessibilityLabel="Email" />
            <TextInput style={ [loginPage.input, helpPage.input] } onChangeText={onChangeEmail} value={email} disabled={ true } />
          </View>
          <Text style={ [helpPage.label, helpPage.subjectLabel] }>Subject</Text>
          <View style={ [loginPage.inputBox, helpPage.subjectBox] }>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/infoCircle.svg") }} style={ [styles.smallIcon, helpPage.inputIcon] } accessibilityLabel="Subject" />
            { (helpSubject === "") ? <Text style={ [loginPage.ghostLabel, helpPage.ghostLabel] }>Subject</Text> : null }
            <TextInput style={ [loginPage.input, helpPage.input] } onChangeText={onChangeHelpSubject} value={ helpSubject } />
          </View>
          <Text style={ [helpPage.label, helpPage.detailsLabel] }>Details</Text>
          <View style={ [loginPage.inputBox, helpPage.detailsBox] }>
            { (helpDetails === "") ? <Text style={ [loginPage.ghostLabel, helpPage.ghostLabel, helpPage.detailsGhostLabel] }>Details of the issues</Text> : null }
            <TextInput style={ [loginPage.input, helpPage.input, helpPage.detailsInput] } onChangeText={onChangeHelpDetails} value={ helpDetails } multiline={ true } />
          </View>
          <TouchableOpacity style={ [helpPage.fullNextButton, (disabled ? styles.buttonInactive : styles.buttonActive)] } onPress={ SubmitHelp } disabled={ disabled }>
            <Text style={ styles.whiteButtonLabel }>Submit</Text>
          </TouchableOpacity>
          { GetBottomNav() }
          { popupView }
        </View>
      </View>
    );
  } else if( page === "forgotPasswordPage" ) {
    let disabled = (email === "");
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <TouchableOpacity style={ [styles.backButton, styles.roundButton] } onPress={() => { onChangePage(SafeGet(previousPage,previousPage.length - 1)); onChangePreviousPage(previousPage.slice(0, -1)); }}>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/backArrow.svg") }} style={ detailsPage.backButtonIcon } />
          </TouchableOpacity>
          <Text style={ [styles.pageLabel, styles.rewindable] }>Forgot Password</Text>
          <View style={ searchPage.infoBox }>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/cocoNeutralCircle.svg") }} accessibilityLabel="Support" style={ searchPage.characterImage } />
            <View style={ searchPage.textBox }>
              <Text style={ searchPage.characterLabel }>Oh no! Enter your email below and I'll send you a link to reset your password.</Text>
            </View>
          </View>
          <Text style={ [helpPage.label, forgotPasswordPage.emailLabel] }>Your Email</Text>
          <View style={ [loginPage.inputBox, forgotPasswordPage.emailBox] }>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/email.svg") }} style={ [styles.smallIcon, helpPage.inputIcon] } accessibilityLabel="Email" />
            { (email === "") ? <Text style={ [loginPage.ghostLabel, helpPage.ghostLabel] }>Email</Text> : null }
            <TextInput style={ [loginPage.input, helpPage.input] } onChangeText={onChangeEmail} value={email} />
          </View>
          <TouchableOpacity style={ [forgotPasswordPage.nextButton, (disabled ? styles.buttonInactive : styles.buttonActive)] } onPress={ SubmitForgotPassword } disabled={ disabled }>
            <Text style={ styles.whiteButtonLabel }>Submit</Text>
          </TouchableOpacity>
          <TouchableOpacity style={ [forgotPasswordPage.backButton, styles.whiteButtonActive] } onPress={() => onChangePage("login")}>
            <Text style={ styles.blueButtonLabel } accessibilityLabel="Back to Login">Back to Login</Text>
          </TouchableOpacity>
          { popupView }
        </View>
      </View>
    );
  } else if( page.substr(0,5) === "event" ) {
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <ScrollView style={ styles.scroller }>
            <Image source={{ "uri":thisEvent.portraitImageURL }} style={ styles.portraitEventImage } />
            <TouchableOpacity style={ [detailsPage.likeButton, styles.roundButton] } onPress={() => LikeEvent(thisEvent.eventID, !thisEvent.liked)}>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + (thisEvent.liked ? "" : "in") + "activeLikeIcon.svg") }} style={ detailsPage.likeButtonIcon } />
            </TouchableOpacity>
            <View style={ detailsPage.infoZone }>
              <Text style={ detailsPage.titleLabel }>{ thisEvent.shortTitle }</Text>
              { (thisEvent.averageRating && thisEvent.numberRatings)
                ? <View style={ detailsPage.venueRatings }>
                    <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/star.svg") }} style={ detailsPage.starIcon } />
                    <Text style={ detailsPage.venueRatingsLabel } >{ thisEvent.averageRating + " (" + thisEvent.numberRatings + ")" }</Text>
                  </View>
                : <View></View>
              }
              <Text style={ detailsPage.descriptionLabel } >{ thisEvent.description }</Text>
            </View>
          </ScrollView>
          <TouchableOpacity style={ [detailsPage.backButton, styles.roundButton] } onPress={() => { onChangePage(SafeGet(previousPage,previousPage.length - 1)); onChangePreviousPage(previousPage.slice(0, -1)); }}>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/backArrow.svg") }} style={ detailsPage.backButtonIcon } />
          </TouchableOpacity>
          { popupView }
        </View>
      </View>
    );
  } else if( page.substr(0,7) === "booking" ) {
    let d = new Date( parseInt(thisBooking.unixTime) * 1000 );
    let d2 = new Date( (parseInt(thisBooking.unixTime) + parseInt((thisBooking.timeTotal !== undefined) ? thisBooking.timeTotal : 0) * 60) * 1000 );
    let calendarState = true;
    let attendeesState = true;
    let checkinState = IsBookingNow(thisBooking);
    employeeInfo.myChats.forEach((loopChat) => {
      if( (loopChat.groupID === thisBooking.groupID) && loopChat.isMe && loopChat.isCheckin ) {
        checkinState = false;
      }
    });
    let chatState = IsBookingNow(thisBooking);
    let surveyState = IsBookingOver(thisBooking);
    let cancelState = !surveyState;
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <ScrollView style={ styles.scroller }>
            <Image source={{ "uri":thisBooking.event.portraitImageURL }} style={ styles.portraitEventImage } />
            <TouchableOpacity style={ [detailsPage.likeButton, styles.roundButton] } onPress={() => LikeEvent(thisBooking.event.eventID, !thisBooking.event.liked)}>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + (thisBooking.event.liked ? "" : "in") + "activeLikeIcon.svg") }} style={ detailsPage.likeButtonIcon } />
            </TouchableOpacity>
            <View style={ [detailsPage.infoZone, ((thisBooking.affinityTag ?? false) ? detailsPage.infoZoneAffinity : [])] }>
              { (thisBooking.affinityTag ?? false) ? <Text style={ detailsPage.affinityTag }>{ thisBooking.affinityTag }</Text> : <View></View> }
              <Text style={ detailsPage.titleLabel }>{ thisBooking.event.shortTitle }</Text>
              { (thisBooking.event.averageRating && thisBooking.event.numberRatings)
                ? <View style={ detailsPage.venueRatings }>
                    <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/star.svg") }} style={ detailsPage.starIcon } />
                    <Text style={ detailsPage.venueRatingsLabel } >{ thisBooking.event.averageRating + " (" + thisBooking.event.numberRatings + ")" }</Text>
                  </View>
                : <View></View>
              }
              <Text style={ detailsPage.descriptionLabel } >{ thisBooking.event.description }<br /></Text>
              <View>
                { // the booking has been proposed, but not yet accepted or declined. show them the accept/decline buttons
                  ((thisBooking.status === "potential") && (d.getTime() > Date.now()))
                  ? <View style={ detailsPage.acceptButtonBox}>
                      <TouchableOpacity style={ [detailsPage.acceptButton, styles.buttonActive] } onPress={() => AcceptBooking(thisBooking.groupID)}>
                        <Text style={ styles.whiteButtonLabel }>Accept</Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={ [detailsPage.declineButton, styles.whiteButtonActive] } onPress={() => PreflightDeclineBooking(thisBooking.groupID)}>
                        <Text style={ styles.blueButtonLabel }>Decline</Text>
                      </TouchableOpacity>
                    </View>
                  // the booking has been accepted, but has not yet been rated. show them the in-event context button.
                  : ((thisBooking.status === "accepted")
                    ? <View style={ detailsPage.acceptButtonBox }>
                        <TouchableOpacity style={ [detailsPage.contextButton, (checkinState || surveyState) ? styles.buttonActive : styles.buttonInactive] } disabled={(!checkinState && !surveyState)} onPress={() => surveyState ? PreflightSurvey(thisBooking.groupID) : (checkinState ? BookingCheckin(thisBooking.groupID, thisBooking.meetingSpot) : null)}>
                          <Text style={ styles.whiteButtonLabel }>{ surveyState ? "Post-Event Survey" : (checkinState ? "Check-In" : (chatState ? "You have checked in!" : "Check-In")) }</Text>
                        </TouchableOpacity>
                      </View>
                    : <View></View>
                    )
                }
                <View style={ detailsPage.timeCostBox }>
                  <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/orangeCalendar.svg") }} style={ detailsPage.calendarIcon } />
                  <Text style={ detailsPage.calendarLabel } >{ SafeGet(weekdays, d.getDay()) + " " + SafeGet(months, d.getMonth()) + " " + d.getDate() + ", " + d.getFullYear() }</Text>
                  <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/clock.svg") }} style={ detailsPage.timeIcon } />
                  <Text style={ detailsPage.timeLabel } >{ ((((d.getHours() === 0) || (d.getHours() === 12)) ? "12" : (d.getHours() % 12)) + ":" + (((d.getMinutes() < 10) ? "0" : "") + d.getMinutes()) + ((d.getHours() < 12) ? "AM" : "PM")) + " - " +
                                                           ((((d2.getHours() === 0) || (d2.getHours() === 12)) ? "12" : (d2.getHours() % 12)) + ":" + (((d2.getMinutes() < 10) ? "0" : "") + d2.getMinutes()) + ((d2.getHours() < 12) ? "AM" : "PM")) }
                    <Text style={ detailsPage.durationLabel }>{ " (" + Math.round((d2.getTime() - d.getTime()) / 60000) + " minutes)" }</Text>
                  </Text>
                  <TouchableOpacity onPress={() => ShowAttendees(thisBooking)} style={ detailsPage.attendeesButton }>
                    <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/orangeProfile.svg") }} style={ detailsPage.attendeesIcon } />
                    <Text style={ detailsPage.attendeesLabel } >View Attendees</Text>
                  </TouchableOpacity>
                  <View style={ detailsPage.costBox }>
                    <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/orangeCost.svg") }} style={ detailsPage.costIcon } />
                    <Text style={ detailsPage.costLabel } >Approx. [${ thisBooking.reviewCost }]
                      <Text style={ detailsPage.reimbursementReminderLabel }> (Please see the Reimbursement Policy below)</Text>
                    </Text>
                  </View>
                  { ((thisBooking.status === "accepted") || (thisBooking.status === "historical"))
                    ? <View>
                        <View style={ detailsPage.dividerLine }></View>
                        <Text style={ detailsPage.meetingLocationHeader }>Meeting Location</Text>
                        <Text style={ detailsPage.meetingLocationPolicy }>{ thisBooking.meetingSpot + "\n\n" }There will be a 15 minute grace period for all attendees to arrive once the event time begins.</Text>
                      </View>
                    : <View></View>
                  }
                </View>
                { // the booking is accepted but hasn't happened yet, so show them the in-event buttons
                  ((thisBooking.status === "accepted") && !surveyState)
                  ? <View style={ detailsPage.inEventButtonsBox }>
                      <View style={ detailsPage.airgap }></View>
                      <View style={ [detailsPage.eventButton, detailsPage.calendarButton, (calendarState ? detailsPage.eventButtonActive : detailsPage.eventButtonInactive)] }>
                        <TouchableOpacity style={ detailsPage.eventButtonTouchable } onPress={() => ShowCalendarButtons(thisBooking)} disabled={ !calendarState }>
                          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + (calendarState ? "" : "in") + "activeAddToCalendar.svg") }} style={ detailsPage.eventButtonIcon } />
                        </TouchableOpacity>
                        <Text style={ detailsPage.eventButtonLabel }>Add to{"\n"}Calendar</Text>
                      </View>
                      <View style={ [detailsPage.eventButton, detailsPage.chatButton, (chatState ? detailsPage.eventButtonActive : detailsPage.eventButtonInactive)] }>
                        <TouchableOpacity style={ detailsPage.eventButtonTouchable } onPress={() => { scrollInfo.current = {"offset":0, "result":0}; onChangePage("chat" + thisBooking.groupID); RefreshChat(thisBooking.groupID);}} disabled={ !chatState }>
                          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + (chatState ? "" : "in") + "activeChat.svg") }} style={ detailsPage.eventButtonIcon } />
                        </TouchableOpacity>
                        <Text style={ detailsPage.eventButtonLabel }>Chat</Text>
                      </View>
                      <View style={ [detailsPage.eventButton, detailsPage.cancelButton, (cancelState ? detailsPage.eventButtonActive : detailsPage.eventButtonInactive)] }>
                        <TouchableOpacity style={ detailsPage.eventButtonTouchable } onPress={() => PreflightDeclineBooking(thisBooking.groupID)} disabled={ !cancelState }>
                          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + (cancelState ? "" : "in") + "activeCancel.svg") }} style={ detailsPage.eventButtonIcon } />
                        </TouchableOpacity>
                        <Text style={ detailsPage.eventButtonLabel }>Cancel{"\n"}Attendance</Text>
                      </View>
                    </View>
                  // this is a past booking they attended
                  : ((thisBooking.status === "historical")
                    ? <View style={ detailsPage.ratingBox }>
                        <View style={ detailsPage.airgap }></View>
                        <Text style={ detailsPage.rateEventLabel }>Event</Text>
                        <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((thisBooking.bookingRating >= 1) ? "" : "in") + "activeLikeIcon.svg") }}
                               style={ detailsPage.historicalHeart } />
                        <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((thisBooking.bookingRating >= 2) ? "" : "in") + "activeLikeIcon.svg") }}
                               style={ detailsPage.historicalHeart } />
                        <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((thisBooking.bookingRating >= 3) ? "" : "in") + "activeLikeIcon.svg") }}
                               style={ detailsPage.historicalHeart } />
                        <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((thisBooking.bookingRating >= 4) ? "" : "in") + "activeLikeIcon.svg") }}
                               style={ detailsPage.historicalHeart } />
                        <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((thisBooking.bookingRating >= 5) ? "" : "in") + "activeLikeIcon.svg") }}
                               style={ [detailsPage.historicalHeart, detailsPage.heart5] } accessibilityLabel={ thisBooking.bookingRating + " heart" + ((thisBooking.bookingRating > 1) ? "s" : "") } />
                        <Text style={ detailsPage.rateGroupLabel }>Group</Text>
                        <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((thisBooking.groupRating >= 1) ? "" : "in") + "activeLikeIcon.svg") }}
                               style={ detailsPage.historicalHeart } />
                        <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((thisBooking.groupRating >= 2) ? "" : "in") + "activeLikeIcon.svg") }}
                               style={ detailsPage.historicalHeart } />
                        <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((thisBooking.groupRating >= 3) ? "" : "in") + "activeLikeIcon.svg") }}
                               style={ detailsPage.historicalHeart } />
                        <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((thisBooking.groupRating >= 4) ? "" : "in") + "activeLikeIcon.svg") }}
                               style={ detailsPage.historicalHeart } />
                        <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((thisBooking.groupRating >= 5) ? "" : "in") + "activeLikeIcon.svg") }}
                               style={ [detailsPage.historicalHeart, detailsPage.heart5] } accessibilityLabel={ thisBooking.groupRating + " heart" + ((thisBooking.groupRating > 1) ? "s" : "") } />
                        <View style={ detailsPage.ratingCommentsBox }>
                          <Text style={ detailsPage.ratingCommentsLabel }>Comments</Text>
                          <Text style={ detailsPage.ratingComments }>{ thisBooking.comments }</Text>
                        </View>
                      </View>
                    : <View></View>)
                }
                <View style={ detailsPage.airgap }></View>
                <Text style={ detailsPage.eventInfoLabel }>Event Information</Text>
                <TouchableOpacity style={ detailsPage.vendorBox } onPress={() => Linking.openURL( thisBooking.vendor.website )} disabled={ !thisBooking.vendor.website }>
                  <Text style={ detailsPage.vendorLabel }>Vendor</Text>
                  <Text style={ detailsPage.vendorNameLabel }>{ thisBooking.vendor.name }</Text>
                  <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/vendorWebsite.svg") }} style={ detailsPage.vendorWebsiteIcon } />
                </TouchableOpacity>
                <View style={ detailsPage.dividerLine }></View>
                <View style={ detailsPage.venueBox }>
                  <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/directions.svg") }} style={ detailsPage.directionsIcon } />
                  <Text style={ detailsPage.venueNameLabel }>{ thisBooking.venue.name }</Text>
                  <Text style={ detailsPage.venueAddressLabel }>{ thisBooking.venue.address.line1 + (thisBooking.venue.address.line2 ? (", " + thisBooking.venue.address.line2) : "") + ", " + thisBooking.venue.address.city + ", " + thisBooking.venue.address.state + " " + thisBooking.venue.address.zip }</Text>
                </View>
                <View style={ detailsPage.dividerLine }></View>
                <TouchableOpacity style={ detailsPage.mapBox } onPress={() => Linking.openURL( GetMapLink(thisBooking.venue.address) )}>
                  <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/map.svg") }} style={ detailsPage.mapIcon } />
                  <Text style={ detailsPage.mapLabel }>Get Directions</Text>
                  <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/send.svg") }} style={ detailsPage.sendIcon } />
                </TouchableOpacity>
                <View style={ detailsPage.airgap }></View>
                <Text style={ detailsPage.reimbursementHeader }>Reimbursement Policy</Text>
                <Text style={ detailsPage.reimbursementPolicy }>Your event is estimated to be ${ thisBooking.reviewCost }. Please follow your organization's expense reimbursement policy.</Text>
                <View style={ detailsPage.dividerLine }></View>
                <Text style={ detailsPage.noShowHeader }>No-Show Event Policy</Text>
                <Text style={ detailsPage.noShowPolicy }>By registering for an event attendees agree to honor their commitment to attend or provide timely notice of cancellation if they are unable to attend. <a href="https://coexperiences.com/no-show-policy" target="_blank" rel="noopener noreferrer">Please see the full policy details here.</a></Text>
                <View style={ detailsPage.dividerLine }></View>
                <Text style={ detailsPage.attendanceHeader }>Attendance Policy</Text>
                <Text style={ detailsPage.attendancePolicy }>This experience may require a minimum number of attendees. If an event is canceled because the minimum isn't met, users will be notified. Be sure to check your email before attending.</Text>
              </View>
            </View>
          </ScrollView>
          <TouchableOpacity style={ [detailsPage.backButton, styles.roundButton] } onPress={() => { onChangePage(SafeGet(previousPage,previousPage.length - 1)); onChangePreviousPage(previousPage.slice(0, -1)); }}>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/backArrow.svg") }} style={ detailsPage.backButtonIcon } />
          </TouchableOpacity>
          { popupView }
        </View>
      </View>
    );
  } else if( page.substr(0,4) === "chat" ) {
    let chatsList = [ {"meetingHeading":true, "meetingSpot":thisBooking.meetingSpot} ];
    let lastSender = -1;
    let lastWasCheckin = false;
    let headingList = "";
    myChats.forEach((loopChat) => {
      if( loopChat.senderID === lastSender ) {
        loopChat.senderID = lastWasCheckin ? -2 : -1;
      } else {
        lastSender = loopChat.senderID;
      }
      lastWasCheckin = loopChat.isCheckin;
      chatsList.push(loopChat);
    });
    thisBooking.attendees.forEach((person) => {
      if( !person.isMe ) {
        headingList = headingList + ((headingList === "") ? "" : ", ") + person.name;
      }
    });
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <TouchableOpacity onPress={() => ShowAttendees(thisBooking)}>
            <Text style={ chatPage.eventTitle }>{ (headingList !== "") ? headingList : "No Other Attendees" }</Text>
          </TouchableOpacity>
          <FlatList style={ chatPage.scroller }
                    key="chatList"
                    ref={flatListRef}
                    data={ chatsList }
                    renderItem={({ item,index,separators }) => (ShowChat(item)) }
                    showsVerticalScrollIndicator="false"
                    onScroll={(e) => { scrollInfo.current = {"result":(e.nativeEvent.layoutMeasurement.height - e.nativeEvent.contentSize.height + e.nativeEvent.contentOffset.y), "offset":e.nativeEvent.contentOffset.y}; }}
                    onLayout={() => { setTimeout(() => { ScrollChatList()}, 30); clearTimeout(chatRefreshTimer.current ?? null); chatRefreshTimer.current = setTimeout(() => { RefreshChat(thisBooking.groupID) }, CHAT_REFRESH_DELAY); }} >
          </FlatList>
          <View>
            <View style={ [chatPage.input, chatPage.inputBackground] }></View>
            { (chatMessage === "") ? <Text style={ [loginPage.ghostLabel, chatPage.inputLabel] }>Type here...</Text> : null }
            <TextInput style={ chatPage.input } onChangeText={onChangeChatMessage} onKeyPress={(e) => { if (e.key === 'Enter') { SendChatMessage(thisBooking.groupID); }}} value={chatMessage} />
            <TouchableOpacity style={ chatPage.sendButton } onPress={() => SendChatMessage(thisBooking.groupID)}>
              <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/send.svg") }} style={ chatPage.sendButtonIcon } />
            </TouchableOpacity>
          </View>
          <TouchableOpacity style={ [chatPage.backButton, styles.roundButton] } onPress={() => { clearTimeout(chatRefreshTimer.current ?? null); onChangePage("booking" + thisBooking.groupID); RefreshChat(thisBooking.groupID);}}>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/backArrow.svg") }} style={ detailsPage.backButtonIcon } />
          </TouchableOpacity>
          <TouchableOpacity style={ [chatPage.helpButton, styles.roundButton] } onPress={() => onChangePopupView(
              <View style={ styles.popup }>
                <View style={ styles.popupPanel }></View>
                <View style={ chatHelpPopup.panel }>
                  <Text style={ chatHelpPopup.title }>Chat Help</Text>
                  <Text style={ chatHelpPopup.description }>This chat is designed to help you coordinate with your coworkers while attending an event. You'll see when they check into the event and any messages they have sent for you.{"\n\n"}
                    To send a message to everyone in your group who is attending the event, type it at the bottom of the page and push the send button. To delete a message you have previously sent or to report an inappropriate message from a coworker, press and hold the message in question until the menu pops up.
                  </Text>
                  <TouchableOpacity style={ [chatHelpPopup.closeButton, styles.buttonActive] } onPress={() => onChangePopupView("")}>
                    <Text style={ styles.whiteButtonLabel }>Close</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}>
            <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/infoCircle.svg") }} style={ chatPage.helpButtonIcon } />
          </TouchableOpacity>
          { popupView }
        </View>
      </View>
    );
  } else if( page.substr(0,6) === "survey" ) {
    let ratings = [1,2,3,4,5];
    let attendValue = "";
    attend.forEach((item) => { if( item.selected ) attendValue = item.value} );
    let enableButton = (attendValue === false) || (attendValue && (bookingRating>0) && (groupRating>0));
    return (
      <View style={ [styles.mainContainer, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Text style={ styles.pageLabel }>Post-Event Survey</Text>
          <Text style={ [surveyPage.attendQ, surveyPage.question] }>1. Did you attend this event? <Text style={ styles.bold }>{ thisBooking.shortTitle }</Text></Text>
          <View style={ surveyPage.attendBox }>
            {attend.map((item) => (
              <View style={ [surveyPage.attendButton, (item.caption === "Yes") ? surveyPage.attendGap : null] }>
                <RadioButton onPress={() => { onRadioBtnClick(item, attend, onSetAttend); }} selected={item.selected} skinny={false} survey={true} key={item.answerID} >
                  {item.caption}
                </RadioButton>
              </View>
            ))}
          </View>
          <Text style={ [surveyPage.eventQ, surveyPage.question] }>2. Use the hearts to mark how much you enjoyed this event.</Text>
          <View style={ surveyPage.bookingRatingBox }>
            { ratings.map((item) => (
              <TouchableOpacity style={ surveyPage.ratingButton } onPress={() => { onChangeBookingRating(item); }} >
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((bookingRating < item) ? "in" : "") + "activeLikeIcon.svg") }} accessibilityLabel="{ item }" style={ surveyPage.ratingButton } />
              </TouchableOpacity>
            ))}
          </View>
          <Text style={ [surveyPage.eventHatedIt, surveyPage.ratingCaption] }>Hated It</Text>
          <Text style={ [surveyPage.eventLovedIt, surveyPage.ratingCaption] }>Loved It</Text>
          <Text style={ [surveyPage.groupQ, surveyPage.question] }>3. Use the hearts to mark how much you enjoyed your group members.</Text>
          <View style={ surveyPage.groupRatingBox }>
            { ratings.map((item) => (
              <TouchableOpacity style={ surveyPage.ratingButton } onPress={() => { onChangeGroupRating(item); }} >
                <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/" + ((groupRating < item) ? "in" : "") + "activeLikeIcon.svg") }} accessibilityLabel="{ item }" style={ surveyPage.ratingButton } />
              </TouchableOpacity>
            ))}
          </View>
          <Text style={ [surveyPage.groupHatedIt, surveyPage.ratingCaption] }>Hated It</Text>
          <Text style={ [surveyPage.groupLovedIt, surveyPage.ratingCaption] }>Loved It</Text>
          <Text style={ [surveyPage.commentQ, surveyPage.question] }>4. Is there anything you would want to change about your experience? Or any comments?</Text>
          { (ratingComment === "") ? <Text style={ [loginPage.ghostLabel, surveyPage.commentLabel] }>Type comment here....</Text> : null }
          <TextInput style={ surveyPage.commentInput } onChangeText={onChangeRatingComment} value={ratingComment} multiline="true" />
          { (attendValue === false) ? <View style={ surveyPage.blocker }></View> : null }
          <TouchableOpacity style={ [surveyPage.submitButton, (enableButton ? styles.buttonActive : styles.buttonInactive)] } onPress={() => SubmitRating(thisBooking.groupID)} disabled={ !enableButton }>
            <Text style={ styles.whiteButtonLabel }>Submit</Text>
          </TouchableOpacity>
          <TouchableOpacity style={ [surveyPage.cancelButton, styles.whiteButtonActive] } onPress={() => { onChangePage(SafeGet(previousPage,previousPage.length - 1)); onChangePreviousPage(previousPage.slice(0, -1)); }}>
            <Text style={ styles.blueButtonLabel }>Cancel</Text>
          </TouchableOpacity>
          { popupView }
        </View>
      </View>
    );
  } else if( page === "claimAccount" ) {
    return (
      <View style={ [claimAccountPage.container, loginPage.container, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/CoexperiencesLogo.svg") }} style={ loginPage.mainLogo } accessibilityLabel="Belong For Me logo" />
          <View style={ claimAccountPage.inputBox }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/profile_circle.svg") }} style={ [styles.smallIcon, claimAccountPage.profileIcon1] } accessibilityLabel="First Name" />
          <Text style={ [claimAccountPage.label, claimAccountPage.firstNameLabel] }>{ employeeInfo.firstName }</Text>
          <View style={ [loginPage.inputLine, claimAccountPage.inputLine1] }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/profile_circle.svg") }} style={ [styles.smallIcon, claimAccountPage.profileIcon2] } accessibilityLabel="Last Name" />
          <Text style={ [claimAccountPage.label, claimAccountPage.lastNameLabel] }>{ employeeInfo.lastName }</Text>
          <View style={ [loginPage.inputLine, claimAccountPage.inputLine2] }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/email.svg") }} style={ [styles.smallIcon, claimAccountPage.emailIcon] } accessibilityLabel="Email" />
          <Text style={ [claimAccountPage.label, claimAccountPage.emailLabel] }>{ email }</Text>
          <View style={ [loginPage.inputLine, claimAccountPage.inputLine3] }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/password.svg") }} style={ [styles.smallIcon, claimAccountPage.passwordIcon1] } accessibilityLabel="Password" />
          { (password === "") ? <Text style={ [loginPage.ghostLabel, claimAccountPage.passwordLabel] }>Password</Text> : null }
          <TextInput style={ [loginPage.input, claimAccountPage.passwordInput] } onChangeText={onChangePassword} value={password} secureTextEntry={true}
                     onKeyPress={(e) => { if((e.key === 'Enter') && (password !== "") && (confirmPassword === password)) SubmitClaimAccount(); }} />
          <View style={ [loginPage.inputLine, claimAccountPage.inputLine4] }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/password.svg") }} style={ [styles.smallIcon, claimAccountPage.passwordIcon2] } accessibilityLabel="Confirm Password" />
          { (confirmPassword === "") ? <Text style={ [loginPage.ghostLabel, claimAccountPage.confirmPasswordLabel] }>Confirm Password</Text> : null }
          <TextInput style={ [loginPage.input, claimAccountPage.confirmPasswordInput] } onChangeText={onChangeConfirmPassword} value={confirmPassword} secureTextEntry={true}
                     onKeyPress={(e) => { if((e.key === 'Enter') && (password !== "") && (confirmPassword === password)) SubmitClaimAccount(); }} />
          <TouchableOpacity style={ [claimAccountPage.claimAccountButton, (((password === "") || (confirmPassword !== password)) ? styles.buttonInactive : styles.buttonActive)] }
                            onPress={SubmitClaimAccount} disabled={ ((password === "") || (confirmPassword !== password)) }>
            <Text style={ styles.whiteButtonLabel } accessibilityLabel="Claim Account">Claim Account</Text>
          </TouchableOpacity>
          <Text style={ claimAccountPage.passwordRules }>Passwords must include a minimum of:<br/>{'\u25CF'} 8 characters<br/>{'\u25CF'} one capital letter<br/>{'\u25CF'} one lowercase character<br/>{'\u25CF'} one digit or special character</Text>
          <Text style={ [claimAccountPage.footerLinks, loginPage.footerLinks] }>
            <a href="https://coexperiences.com/privacy" target="_blank" rel="noopener noreferrer"><Text accessibilityLabel="Privacy Policy">Privacy Policy</Text></a>
            <Text>&nbsp;&#8226;&nbsp;</Text>
            <a href="https://coexperiences.com/terms-of-service" target="_blank" rel="noopener noreferrer"><Text accessibilityLabel="Terms of Service">Terms of Service</Text></a>
            <Text style={claimAccountPage.footerWarning}>By claiming your account, you acknowledge that you have read, understood, and agree to be bound by the Belong For Me Terms of Service and Privacy Policy.</Text>
          </Text>
        </View>
      </View>
    );
  } else if( page === "resetPassword" ) {
    return (
      <View style={ [loginPage.container, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/CoexperiencesLogo.svg") }} style={ loginPage.mainLogo } accessibilityLabel="Belong For Me logo" />
          <View style={ claimAccountPage.inputBox }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/profile_circle.svg") }} style={ [styles.smallIcon, claimAccountPage.profileIcon1] } accessibilityLabel="First Name" />
          <Text style={ [claimAccountPage.label, claimAccountPage.firstNameLabel] }>{ employeeInfo.firstName }</Text>
          <View style={ [loginPage.inputLine, claimAccountPage.inputLine1] }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/profile_circle.svg") }} style={ [styles.smallIcon, claimAccountPage.profileIcon2] } accessibilityLabel="Last Name" />
          <Text style={ [claimAccountPage.label, claimAccountPage.lastNameLabel] }>{ employeeInfo.lastName }</Text>
          <View style={ [loginPage.inputLine, claimAccountPage.inputLine2] }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/email.svg") }} style={ [styles.smallIcon, claimAccountPage.emailIcon] } accessibilityLabel="email" />
          <Text style={ [claimAccountPage.label, claimAccountPage.emailLabel] }>{ email }</Text>
          <View style={ [loginPage.inputLine, claimAccountPage.inputLine3] }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/password.svg") }} style={ [styles.smallIcon, claimAccountPage.passwordIcon1] } accessibilityLabel="Password" />
          { (password === "") ? <Text style={ [loginPage.ghostLabel, claimAccountPage.passwordLabel] }>Password</Text> : null }
          <TextInput style={ [loginPage.input, claimAccountPage.passwordInput] } onChangeText={onChangePassword} value={password} secureTextEntry={true}
                     onKeyPress={(e) => { if((e.key === 'Enter') && (password !== "") && (confirmPassword === password)) SubmitResetPassword(); }} />
          <View style={ [loginPage.inputLine, claimAccountPage.inputLine4] }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/password.svg") }} style={ [styles.smallIcon, claimAccountPage.passwordIcon2] } accessibilityLabel="Confirm Password" />
          { (confirmPassword === "") ? <Text style={ [loginPage.ghostLabel, claimAccountPage.confirmPasswordLabel] }>Confirm Password</Text> : null }
          <TextInput style={ [loginPage.input, claimAccountPage.confirmPasswordInput] } onChangeText={onChangeConfirmPassword} value={confirmPassword} secureTextEntry={true}
                     onKeyPress={(e) => { if((e.key === 'Enter') && (password !== "") && (confirmPassword === password)) SubmitResetPassword(); }} />
          <TouchableOpacity style={ [claimAccountPage.claimAccountButton, (((password === "") || (confirmPassword !== password)) ? styles.buttonInactive : styles.buttonActive)] }
                            onPress={SubmitResetPassword} disabled={ ((password === "") || (confirmPassword !== password)) }>
            <Text style={ styles.whiteButtonLabel } accessibilityLabel="Reset Password">Reset Password</Text>
          </TouchableOpacity>
          <Text style={ claimAccountPage.passwordRules }>Passwords must include a minimum of:<br/>{'\u25CF'} 8 characters<br/>{'\u25CF'} one capital letter<br/>{'\u25CF'} one lowercase character<br/>{'\u25CF'} one digit or special character</Text>
          <Text style={ loginPage.footerLinks }>
            <a href="https://coexperiences.com/privacy" target="_blank" rel="noopener noreferrer"><Text accessibilityLabel="Privacy Policy">Privacy Policy</Text></a>
            <Text>&nbsp;&#8226;&nbsp;</Text>
            <a href="https://coexperiences.com/terms-of-service" target="_blank" rel="noopener noreferrer"><Text accessibilityLabel="Terms of Service">Terms of Service</Text></a>
          </Text>
        </View>
      </View>
    );
  } else {
    return (
      <View style={ [loginPage.container, styles.fillHeight] }>
        <View style={ styles.boundWidth }>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/CoexperiencesLogo.svg") }} style={ loginPage.mainLogo } accessibilityLabel="Belong For Me logo" />
          <View style={ loginPage.inputBox }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/email.svg") }} style={ [styles.smallIcon, loginPage.emailIcon] } accessibilityLabel="Email" />
          { (email === "") ? <Text style={ [loginPage.ghostLabel, loginPage.emailLabel] }>Email</Text> : null }
          <TextInput style={ [loginPage.input, loginPage.emailInput] } onChangeText={onChangeEmail} onKeyPress={(e) => { if((e.key === 'Enter') && (email !== "") && (password !== "")) SubmitLogin(); }} value={email} />
          <View style={ [loginPage.inputLine, loginPage.inputLine1] }></View>
          <Image source={{ "uri":(APP_ASSETS_BUCKET + "/icons/password.svg") }} style={ [styles.smallIcon, loginPage.passwordIcon] } accessibilityLabel="Password" />
          { (password === "") ? <Text style={ [loginPage.ghostLabel, loginPage.passwordLabel] }>Password</Text> : null }
          <TextInput style={ [loginPage.input, loginPage.passwordInput] } onChangeText={onChangePassword} onKeyPress={(e) => { if((e.key === 'Enter') && (email !== "") && (password !== "")) SubmitLogin(); }} value={password} secureTextEntry={true} />
          <TouchableOpacity style={ [loginPage.loginButton, (((email === "") || (password === "") || (processingLogin === true)) ? styles.buttonInactive : styles.buttonActive)] }
                            onPress={SubmitLogin} disabled={ ((email === "") || (password === "") || (processingLogin === true)) }>
            <Text style={ styles.whiteButtonLabel } accessibilityLabel="Log In">{ (processingLogin === true) ? "Logging in..." : "Log In" }</Text>
          </TouchableOpacity>
          <TouchableOpacity style={ loginPage.forgotPassword } onPress={() => { onChangePreviousPage([page]); onChangePage("forgotPasswordPage"); }}>
            <Text style={ loginPage.forgotPasswordLabel }>Forgot Password?</Text>
          </TouchableOpacity>
          <Text style={ loginPage.footerLinks }>
            <a href="https://coexperiences.com/privacy" target="_blank" rel="noopener noreferrer"><Text accessibilityLabel="Privacy Policy">Privacy Policy</Text></a>
            <Text>&nbsp;&#8226;&nbsp;</Text>
            <a href="https://coexperiences.com/terms-of-service" target="_blank" rel="noopener noreferrer"><Text accessibilityLabel="Terms of Service">Terms of Service</Text></a>
          </Text>
        </View>
      </View>
    );
  }
}

// bound the aspect ratio
let ScreenHeight = Dimensions.get("window").height;
let ScreenWidth = Dimensions.get("window").width;
if( ScreenWidth > (ScreenHeight/2.16) ) {
  ScreenWidth = ScreenHeight/2.16;
}
/*
if ScreenHeight > (ScreenWidth * 2.16) {
  ScreenHeight = ScreenWidth * 2.16;
}
*/
const questionnaire = StyleSheet.create({
  dynamicBox: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.042,
    width: ScreenWidth * 0.82933,
    height: ScreenHeight * 0.80049,
    display: "flex",
    alignItems: "cente1r",
    justifyContent: "center"
  },
  progressLabel: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    width: ScreenWidth * 0.82933,
    marginBottom: ScreenHeight * 0.018473,
    marginTop: ScreenHeight * 0.018473,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    textAlign: "left",
    color: "#959595",
    textTransform: "uppercase"
  },
  questionLabel: {
    width: ScreenWidth * 0.82933,
    marginBottom: ScreenHeight * 0.018473,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.053333,
    fontWeight: 700,
    textAlign: "left",
    color: "#000"
  },
  warningLabel: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.845,
    width: ScreenWidth * 0.82933,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.032,
    fontWeight: 400,
	  textAlign: "center",
    color: "#f00"
  },
  previousButton: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.885,
    width: ScreenWidth * 0.41333,
    height: ScreenHeight * 0.049261,
    borderTopLeftRadius: ScreenWidth * 0.08,
    borderBottomLeftRadius: ScreenWidth * 0.08
  },
  nextButton: {
    position: "absolute",
    left: ScreenWidth * 0.50133,
    top: ScreenHeight * 0.885,
    width: ScreenWidth * 0.41333,
    height: ScreenHeight * 0.049261,
    borderTopRightRadius: ScreenWidth * 0.08,
    borderBottomRightRadius: ScreenWidth * 0.08
  },
  backButton: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.91133,
    width: ScreenWidth * 0.82933,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  optionBox: {
    display: "inline-block",
    width: ScreenWidth * 0.82933
  },
  optionButton: {
    position: "relative",
    display: "inline-block",
    width: "100%",
    marginTop: ScreenHeight * 0.012315,
    justifyContent: "center"
  },
  optionButtonSkinny: {
	  marginTop: ScreenHeight * 0.0086207
  },
  superCaptionLabel: {
    width: ScreenWidth * 0.82933,
    marginBottom: ScreenHeight * 0.049261,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.042667,
    fontWeight: 400,
    textAlign: "left",
    color: "#000"
  },
  noteLabel: {
    width: ScreenWidth * 0.82933,
    marginTop: ScreenHeight * -0.0098522,
    marginBottom: ScreenHeight * 0.018473,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    textAlign: "left",
    color: "#959595"
  },
  footnoteLabel: {
    width: ScreenWidth * 0.82933,
    marginTop: ScreenHeight * 0.024631,
    marginBottom: ScreenHeight * 0.024631,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    textAlign: "left",
    color: "#959595"
  },
  characterLabel: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.060345,
    width: ScreenWidth * 0.82933,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.058667,
    fontWeight: 700,
    textAlign: "center",
    color: "#000"
  },
  character: {
    position: "absolute",
    left: ScreenWidth * 0.34133,
    top: ScreenHeight * 0.13177,
    width: ScreenWidth * 0.32,
    height: ScreenHeight * 0.19581
  },
  fullNextButton: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.84483,
    width: ScreenWidth * 0.82933,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  explainLabel: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.38547,
    width: ScreenWidth * 0.82933,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    color: "#000"
  }
});

const interests = StyleSheet.create({
  interestBox: {
    position: "absolute",
    top: ScreenHeight * 0.022167,
    width: ScreenWidth,
    height: ScreenHeight * 0.9585,
    display: "inline-block",
    alignItems: "center",
    justifyContent: "center"
  },
  progressLabel: {
    marginBottom: ScreenHeight * 0.018473,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    textAlign: "left",
    color: "#959595",
    textTransform: "uppercase"
  },
  categoryBlock: {
    display: "inline-block",
    width: ScreenWidth * 0.936,
    marginLeft: ScreenWidth * 0.032
  },
  interestButton: {
    display: "inline-block",
    width: ScreenWidth * 0.312,
    verticalAlign: "top"
  },
  checkboxButton: {
    position: "absolute",
    width: ScreenWidth * 0.26933,
    height: ScreenHeight * 0.12438,
    left: ScreenWidth * 0.024,
    top: ScreenHeight * 0.011084,
    borderRadius: ScreenWidth * 0.026667
  },
  checkboxButtonOn: {
    backgroundColor: 'rgba(0,0,0,0.25)',
    zIndex: 1
  },
  interestScreenshotOn: {
    borderColor: "#39b8ff"
  },
  interestScreenshot: {
    display: "inline-block",
    width: ScreenWidth * 0.26933,
    height: ScreenHeight * 0.12438,
    margin: ScreenWidth * 0.024,
    borderRadius: ScreenWidth * 0.026667,
    borderColor: "#fff",
    borderStyle: "solid",
    borderWidth: 3
  },
  interestLabel: {
    display: "inline-block",
    width: ScreenWidth * 0.312,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    textAlign: "center",
    color: "#000"
  },
  interestHeaderLabel: {
    display: "inline-block",
    marginLeft: ScreenWidth * 0.024,
    width: ScreenWidth * 0.888,
    marginBottom: ScreenHeight * 0.018473,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.058667,
    fontWeight: 700,
    textAlign: "center",
    color: "#000"
  },
  categoryLabel: {
    display: "inline-block",
    marginLeft: ScreenWidth * 0.024,
    width: ScreenWidth * 0.888,
    marginBottom: ScreenHeight * 0.018473,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.042667,
    fontWeight: 700,
    textAlign: "left",
    color: "#000"
  },
  dividerLine: {
    display: "inline-block",
    marginLeft: ScreenWidth * 0.024,
    marginBottom: ScreenHeight * 0.012315,
    width: ScreenWidth * 0.888,
    height: 0,
    borderColor: "#dedede",
    borderStyle: "solid",
    borderWidth: 1,
    borderBottomWidth: 0
  },
  airGap: {
    display: "inline-block",
    width: ScreenWidth * 0.936,
    height: ScreenHeight * 0.0061576
  },
  warningLabel: {
    display: "inline-block",
    marginLeft: ScreenWidth * 0.085333,
    marginBottom: ScreenHeight * 0.018473,
    width: ScreenWidth * 0.82933,
    height: ScreenHeight * 0.018473,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.032,
    fontWeight: 400,
    textAlign: "center",
    color: "#f00"
  },
  navButtonBox: {
    display: "inline-block",
    width: ScreenWidth,
    height: ScreenHeight * 0.05788,
    marginBottom: ScreenHeight * 0.024631
  },
  previousButton: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    width: ScreenWidth * 0.41333,
    height: ScreenHeight * 0.049261,
    borderTopLeftRadius: ScreenWidth * 0.08,
    borderBottomLeftRadius: ScreenWidth * 0.08
  },
  nextButton: {
    position: "absolute",
    left: ScreenWidth * 0.50133,
    width: ScreenWidth * 0.41333,
    height: ScreenHeight * 0.049261,
    borderTopRightRadius: ScreenWidth * 0.08,
    borderBottomRightRadius: ScreenWidth * 0.08
  },
  backButton: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.066502,
    width: ScreenWidth * 0.82933,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const footprints = StyleSheet.create({
  top1: {
    position: "absolute",
    left: ScreenWidth * 0.664,
    top: ScreenHeight * 0.046798,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  top2: {
    position: "absolute",
    left: ScreenWidth * 0.77067,
    top: ScreenHeight * 0.076355,
    width: ScreenWidth * 0.088,
    height: ScreenHeight * 0.04064
  },
  top3: {
    position: "absolute",
    left: ScreenWidth * 0.88,
    top: ScreenHeight * 0.049261,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  }
});

const loginPage = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    flex: 1
  },
  mainLogo: {
    position: "absolute",
    resizeMode: "contain",
    left: ScreenWidth * 0.10133,
    top: ScreenHeight * 0.24631,
    width: ScreenWidth * 0.8,
    height: ScreenHeight * 0.08
  },
  inputBox: {
    position: "absolute",
    left: ScreenWidth * 0.10933,
    top: ScreenHeight * 0.4064,
    width: ScreenWidth * 0.77333,
    height: ScreenHeight * 0.12808,
    borderColor: "#dedede",
    borderRadius: ScreenWidth * 0.026667,
    borderStyle: "solid",
    borderWidth: 1
  },
  inputLine: {
    position: "absolute",
    left: ScreenWidth * 0.10933,
    width: ScreenWidth * 0.77333,
    height: 0,
    borderColor: "#dedede",
    borderStyle: "solid",
    borderWidth: 1,
    borderBottomWidth: 0
  },
  input: {
    position: "absolute",
    left: ScreenWidth * 0.248,
    width: ScreenWidth * 0.62133,
    height: ScreenHeight * 0.038177,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    textAlign: "left",
    padding: ScreenWidth * 0.026667,
    color: "#000"
  },
  ghostLabel: {
    position: "absolute",
    left: ScreenWidth * 0.27467,
    width: ScreenWidth * 0.62133,
    height: ScreenHeight * 0.038177,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    textAlign: "left",
    color: "#959595",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  inputLine1: {
    top: ScreenHeight * 0.47044
  },
  emailIcon: {
    left: ScreenWidth * 0.16,
    top: ScreenHeight * 0.42734
  },
  emailInput: {
    top: ScreenHeight * 0.42364
  },
  emailLabel: {
    top: ScreenHeight * 0.4335
  },
  passwordIcon: {
    left: ScreenWidth * 0.16,
    top: ScreenHeight * 0.48768
  },
  passwordInput: {
    top: ScreenHeight * 0.48645
  },
  passwordLabel: {
    top: ScreenHeight * 0.4963
  },
  forgotPassword: {
    position: "absolute",
    left: ScreenWidth * 0.56267,
    top: ScreenHeight * 0.55419,
    width: ScreenWidth * 0.32,
    textAlign: "right"
  },
  forgotPasswordLabel: {
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    color: "#959595"
  },
  loginButton: {
    position: "absolute",
    left: ScreenWidth * 0.10933,
    top: ScreenHeight * 0.617,
    width: ScreenWidth * 0.77333,
    height: ScreenHeight * 0.049261,
    textAlign: "center",
    borderRadius: ScreenWidth * 0.08
  },
  footerLinks: {
    position: "absolute",
    left: ScreenWidth * 0.10933,
    top: ScreenHeight * 0.96059,
    width: ScreenWidth * 0.77333,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.032,
    fontWeight: 400,
    textAlign: "left",
    color: "#959595"
  }
});

const helpPage = StyleSheet.create({
  label: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 700,
    color: "#000"
  },
  emailLabel: {
    top: ScreenHeight * 0.24631
  },
  subjectLabel: {
    top: ScreenHeight * 0.35714
  },
  detailsLabel: {
    top: ScreenHeight * 0.46798
  },
  emailBox: {
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.27709,
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.064039
  },
  subjectBox: {
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.38793,
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.064039
  },
  detailsBox: {
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.49877,
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.30788
  },
  inputIcon: {
    left: ScreenWidth * 0.050667,
    top: ScreenHeight * 0.017241
  },
  ghostLabel: {
    left: ScreenWidth * 0.16,
    top: ScreenHeight * 0.020936
  },
  input: {
    left: ScreenWidth * 0.13333,
    top: ScreenHeight * 0.013547
  },
  detailsGhostLabel: {
    left: ScreenWidth * 0.034667,
    top: ScreenHeight * 0.01601
  },
  detailsInput: {
    left: ScreenWidth * 0.008,
    top: ScreenHeight * 0.0036946,
    width: ScreenWidth * 0.89333,
    height: ScreenHeight * 0.2968
  },
  fullNextButton: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.84483,
    width: ScreenWidth * 0.82933,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const forgotPasswordPage = StyleSheet.create({
  emailLabel: {
    top: ScreenHeight * 0.37562
  },
  emailBox: {
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.4064,
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.064039
  },
  nextButton: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.617,
    width: ScreenWidth * 0.82933,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  backButton: {
    position: "absolute",
    left: ScreenWidth * 0.085333,
    top: ScreenHeight * 0.6835,
    width: ScreenWidth * 0.82933,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const claimAccountPage = StyleSheet.create({
  container: {
    top: "-100px"
  },
  inputBox: {
    position: "absolute",
    left: ScreenWidth * 0.10933,
    top: ScreenHeight * 0.4064,
    width: ScreenWidth * 0.77333,
    height: ScreenHeight * 0.30542,
    borderColor: "#dedede",
    borderRadius: ScreenWidth * 0.026667,
    borderStyle: "solid",
    borderWidth: 1
  },
  label: {
    position: "absolute",
    width: ScreenWidth * 0.59467,
    height: ScreenHeight * 0.038177,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "left",
    color: "#000",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  inputLine1: {
    top: ScreenHeight * 0.47044
  },
  inputLine2: {
    top: ScreenHeight * 0.52956
  },
  inputLine3: {
    top: ScreenHeight * 0.58744
  },
  inputLine4: {
    top: ScreenHeight * 0.64901
  },
  profileIcon1: {
    left: ScreenWidth * 0.16,
    top: ScreenHeight * 0.42734
  },
  firstNameLabel: {
    left: ScreenWidth * 0.27467,
    top: ScreenHeight * 0.4335
  },
  profileIcon2: {
    left: ScreenWidth * 0.16,
    top: ScreenHeight * 0.48768
  },
  lastNameLabel: {
    left: ScreenWidth * 0.27467,
    top: ScreenHeight * 0.4963
  },
  emailIcon: {
    left: ScreenWidth * 0.16,
    top: ScreenHeight * 0.54801
  },
  emailLabel: {
    left: ScreenWidth * 0.27467,
    top: ScreenHeight * 0.55419
  },
  passwordIcon1: {
    left: ScreenWidth * 0.16,
    top: ScreenHeight * 0.60833
  },
  passwordInput: {
    left: ScreenWidth * 0.248,
    top: ScreenHeight * 0.6034
  },
  passwordLabel: {
    top: ScreenHeight * 0.6133
  },
  passwordIcon2: {
    left: ScreenWidth * 0.16,
    top: ScreenHeight * 0.66867
  },
  confirmPasswordInput: {
    left: ScreenWidth * 0.248,
    top: ScreenHeight * 0.66374
  },
  confirmPasswordLabel: {
    top: ScreenHeight * 0.67365
  },
  claimAccountButton: {
    position: "absolute",
    left: ScreenWidth * 0.10933,
    top: ScreenHeight * 0.73522,
    width: ScreenWidth * 0.77333,
    height: ScreenHeight * 0.049261,
    textAlign: "center",
    borderRadius: ScreenWidth * 0.08
  },
  passwordRules: {
    position: "absolute",
    left: ScreenWidth * 0.10933,
    top: ScreenHeight * 0.80788,
    width: ScreenWidth * 0.77333,
    height: ScreenHeight * 0.055419,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.032,
    fontWeight: "normal",
    fontStyle: "normal"
  },
  footerLinks: {
    textAlign: "center"
  },
  footerWarning: {
    display: "block"
  }
});

const profilePage = StyleSheet.create({
  name: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.066502,
    width: ScreenWidth * 0.72267,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.058667,
    fontWeight: 700,
    color: "#000"
  },
  joinDate: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.10591,
    width: ScreenWidth * 0.72267,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.029333,
    fontWeight: 400,
    color: "#959595"
  },
  startDate: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.12685,
    width: ScreenWidth * 0.72267,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.029333,
    fontWeight: 400,
    color: "#959595"
  },
  logout: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.86207,
  },
  logoutCaption: {
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    textDecorationLine: "underline",
    color: "#000"
  },
  characterImage: {
    position: "absolute",
    left: ScreenWidth * 0.76,
    top: ScreenHeight * 0.061576,
    width: ScreenWidth * 0.19733,
    height: ScreenHeight * 0.091133
  },
  settingsHeading: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.17734,
    width: ScreenWidth * 0.72267,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.058667,
    fontWeight: 700,
    color: "#000"
  },
  settingsBox: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.21552,
    width: ScreenWidth * 0.91467,
  },
  settingsButton: {
    position: "relative",
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.067734,
    borderBottom: "1px solid #ececec"
  },
  settingsIcon: {
    position: "absolute",
    top: ScreenHeight * 0.020936,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  settingsLabel: {
    position: "absolute",
    left: ScreenWidth * 0.090667,
    top: ScreenHeight * 0.023399,
    width: ScreenWidth * 0.76267,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333
  },
  settingsArrow: {
    position: "absolute",
    left: ScreenWidth * 0.86667,
    top: ScreenHeight * 0.028325,
    width: ScreenWidth * 0.016,
    height: ScreenHeight * 0.012315
  },
  privacyBox: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.11946,
    width: ScreenWidth * 0.91467,
  }
});

const homePage = StyleSheet.create({
  welcomeLabel: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.066502,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.058667,
    fontWeight: 700,
    color: "#000"
  },
  label: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    width: ScreenWidth * 0.74933,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.058667,
    fontWeight: 600,
    color: "#000"
  },
  dividerLine: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    width: ScreenWidth * 0.91467,
    height: 0,
    borderColor: "#dedede",
    borderStyle: "solid",
    borderWidth: 1,
    borderBottomWidth: 0
  },
  firstDivider: {
    top: ScreenHeight * 0.11946
  },
  inviteLabel: {
    top: ScreenHeight * 0.14039
  },
  viewAllInvites: {
    top: ScreenHeight * 0.15640
  },
  inviteEvent: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.20074,
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.39039
  },
  emptyInvitesList: {
    position: "absolute",
    left: ScreenWidth * 0.048,
    top: ScreenHeight * 0.11946,
    width: ScreenWidth * 0.904,
    height: ScreenHeight * 0.117,
    borderColor: "#dedede",
    borderRadius: ScreenWidth * 0.026667,
    borderStyle: "solid",
    borderWidth: 1
  },
  emptyInvitesListLarge: {
    height: ScreenHeight * 0.175,
  },
  characterImage: {
    top: ScreenHeight * 0.0465,
  },
  noEventsLabel: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.36946,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#959595"
  },
  secondDivider2: {
    top: ScreenHeight * 0.62069
  },
  secondDivider4: {
    top: ScreenHeight * 0.33621
  },
  likedLabel2: {
    top: ScreenHeight * 0.64163
  },
  likedLabel4: {
    top: ScreenHeight * 0.36575
  },
  emptyLikesList2: {
    top: ScreenHeight * 0.68596
  },
  emptyLikesList4: {
    top: ScreenHeight * 0.4101
  },
  likedList: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    width: ScreenWidth * 0.91467
  },
  likedList2: {
    top: ScreenHeight * 0.68596,
    height: ScreenHeight * 0.22537
  },
  likedList4: {
    top: ScreenHeight * 0.41378,
    height: ScreenHeight * 0.41872
  },
  viewAllLink: {
    position: "absolute",
    left: ScreenWidth * 0.78133,
    width: ScreenWidth * 0.17333,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.026667,
    fontWeight: 400,
    textAlign: "right",
    color: "#000"
  },
  viewAll2: {
    top: ScreenHeight * 0.65764
  },
  viewAll4: {
    top: ScreenHeight * 0.38176
  }
});

const likedEventsPage = StyleSheet.create({
  infoBox: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    left: ScreenWidth * 0.048,
    top: ScreenHeight * 0.11946,
    width: ScreenWidth * 0.904,
    height: ScreenHeight * 0.117,
    borderColor: "#dedede",
    borderRadius: ScreenWidth * 0.026667,
    borderStyle: "solid",
    borderWidth: 1
  },
  list: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.25369,
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.65271
  }
});

const upcomingPage = StyleSheet.create({
  emptyInvitesList: {
    position: "absolute",
    left: ScreenWidth * 0.048,
    top: ScreenHeight * 0.11946,
    width: ScreenWidth * 0.904,
    height: ScreenHeight * 0.117,
    borderColor: "#dedede",
    borderRadius: ScreenWidth * 0.026667,
    borderStyle: "solid",
    borderWidth: 1
  },
  noEventsLabel: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.4064,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#959595"
  },
  list: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.14532,
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.74877
  },
  panel: {
    position: "relative",
    width: "100%",
    borderColor: "#dedede",
    borderRadius: ScreenWidth * 0.026667,
    borderWidth: 1,
    paddingBottom: "2vh",
  },
  bigPanel: {
    paddingBottom: "2vh",
  },
  actionButton: {
    position: "relative",
    left: ScreenWidth * 0.13867,
    paddingBottom: "1vh",
    paddingTop: "1vh",
    width: ScreenWidth * 0.63733,
    borderRadius: ScreenWidth * 0.08,
  },
  dateBox: {
    position: "absolute",
    left: ScreenWidth * 0.021333,
    top: ScreenHeight * 0.014778,
    width: ScreenWidth * 0.224,
    height: ScreenHeight * 0.10345,
    textAlign: "center",
  },
  monthLabel: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.0049261,
    width: "100%",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 700,
    color: "#279af1"
  },
  dateLabel: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.022167,
    width: "100%",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.085333,
    fontWeight: 700,
    color: "#279af1"
  },
  yearLabel: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.068966,
    width: "100%",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 700,
    color: "#279af1"
  },
  title: {
    position: "relative",
    width: ScreenWidth * 0.56,
    left: ScreenWidth * 0.26667,
    top: ScreenHeight * 0.023399,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 600,
    color: "#000",
    paddingBottom: "3vh",
  },
  clock: {
    position: "relative",
    left: ScreenWidth * 0.26667,
    top: "0.6vh",
    width: ScreenWidth * 0.026667,
    height: ScreenHeight * 0.012315
  },
  time: {
    position: "relative",
    top: "-0.8vh",
    left: ScreenWidth * 0.30667,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.029333,
    fontWeight: 400,
    color: "#959595"
  },
  location: {
    position: "relative",
    top: "-0.1vh",
    left: ScreenWidth * 0.26667,
    width: ScreenWidth * 0.026667,
    height: ScreenHeight * 0.012315
  },
  address: {
    position: "relative",
    left: ScreenWidth * 0.30667,
    top: "-1.5vh",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.029333,
    fontWeight: 400,
    color: "#959595",
    paddingBottom: "2vh",
  },
  arrow: {
    position: "absolute",
    left: ScreenWidth * 0.82933,
    top: ScreenHeight * 0.052956,
    width: ScreenWidth * 0.024,
    height: ScreenHeight * 0.022167
  }
});

const cancelledEvent = StyleSheet.create({
  strikeThrough: {
    textDecorationLine: "line-through"
  },
  cancelledButton: {
    backgroundColor: "#ff00003b"
  }
})

const searchPage = StyleSheet.create({
  infoBox: {
    position: "absolute",
    left: ScreenWidth * 0.048,
    top: ScreenHeight * 0.11946,
    width: ScreenWidth * 0.904,
    height: ScreenHeight * 0.099754,
    borderColor: "#dedede",
    borderRadius: ScreenWidth * 0.026667,
    borderStyle: "solid",
    borderWidth: 1
  },
  characterImage: {
    position: "absolute",
    left: ScreenWidth * 0.048,
    top: ScreenHeight * 0.014778,
    width: ScreenWidth * 0.15467,
    height: ScreenHeight * 0.071429
  },
  textBox: {
    position: "absolute",
    left: ScreenWidth * 0.23733,
    top: ScreenHeight * 0.0012315,
    width: ScreenWidth * 0.62133,
    height: ScreenHeight * 0.094826,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  characterLabel: {
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#959595"
  },
  list: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.23645,
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.66995
  }
});

const detailsPage = StyleSheet.create({
  backButton: {
    left: ScreenWidth * 0.050667,
    top: ScreenHeight * 0.052956
  },
  backButtonIcon: {
    position: "absolute",
    left: ScreenWidth * -0.010667,
    top: ScreenHeight * -0.0024631,
    width: ScreenWidth * 0.10667,
    height: ScreenHeight * 0.049261
  },
  likeButton: {
    left: ScreenWidth * 0.856,
    top: ScreenHeight * 0.052956
  },
  likeButtonIcon: {
    position: "absolute",
    left: ScreenWidth * 0.018667,
    top: ScreenHeight * 0.012315,
    width: ScreenWidth * 0.053333,
    height: ScreenHeight * 0.018473
  },
  infoZone: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.63793,
    width: ScreenWidth * 0.872,
    marginHorizontal: ScreenWidth * 0.064,
    marginVertical: ScreenHeight * 0.041872,
    backgroundColor: "#fff"
  },
  infoZoneAffinity: {
    top: ScreenHeight * 0.66872,
  },
  titleLabel: {
    position: "relative",
    left: 0,
    top: 0,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.058667,
    fontWeight: 700,
    color: "#000"
  },
  descriptionLabel: {
    position: "relative",
    left: 0,
    marginTop: ScreenHeight * 0.0073892,
    width: "100%",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000"
  },
  inEventButtonsBox: {
    position: "relative",
    width: "100%",
    height: ScreenHeight * 0.1601
  },
  eventButton: {
    position: "absolute",
    width: ScreenWidth * 0.11733,
    height: ScreenHeight * 0.054187
  },
  eventButtonActive: {
    borderColor: "#279af1",
    color: "#279af1"
  },
  eventButtonInactive: {
    borderColor: "#c9c9c9",
    color: "#c9c9c9"
  },
  eventButtonTouchable: {
    width: "100%",
    height: "100%",
    borderColor: "inherit",
    borderRadius: ScreenWidth * 0.058667,
    borderWidth: 1
  },
  eventButtonIcon: {
    position: "absolute",
    left: ScreenWidth * 0.024,
    top: ScreenHeight * 0.011084,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  eventButtonLabel: {
    position: "absolute",
    left: ScreenWidth * -0.056,
    top: ScreenHeight * 0.061576,
    width: ScreenWidth * 0.22667,
    color: "inherit",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.032,
    fontWeight: 500,
    textAlign: "center"
  },
  calendarButton: {
    left: ScreenWidth * 0.10133,
    top: ScreenHeight * 0.061576
  },
  chatButton: {
    left: ScreenWidth * 0.37867,
    top: ScreenHeight * 0.061576
  },
  cancelButton: {
    left: ScreenWidth * 0.656,
    top: ScreenHeight * 0.061576
  },
  acceptButtonBox: {
    position: "relative",
    width: "100%",
    height: ScreenHeight * 0.049261,
    marginTop: ScreenHeight * 0.029557
  },
  acceptButton: {
    position: "absolute",
    width: ScreenWidth * 0.41867,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  contextButton: {
    position: "absolute",
    width: ScreenWidth * 0.872,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  declineButton: {
    position: "absolute",
    right: 0,
    width: ScreenWidth * 0.41867,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  ratingBox: {
    position: "relative",
    display: "inline-block",
    left: 0,
    width: "100%",
  },
  rateEventLabel: {
    position: "relative",
    display: "inline-block",
    width: ScreenWidth * 0.29333,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  rateGroupLabel: {
    position: "relative",
    display: "inline-block",
    width: ScreenWidth * 0.29333,
    marginTop: ScreenHeight * 0.012315,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  ratingCommentsBox: {
    position: "relative",
    display: "inline-block",
    marginTop: ScreenHeight * 0.012315,
    width: "100%",
  },
  ratingCommentsLabel: {
    position: "absolute",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  ratingComments: {
    position: "relative",
    display: "inline-block",
    marginLeft: ScreenWidth * 0.29867,
    width: ScreenWidth * 0.56,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.032,
    color: "#000",
    textAlign: "left"
  },
  historicalHeart: {
    position: "relative",
    display: "inline-block",
    top: ScreenHeight * 0.0036946,
    width: ScreenWidth * 0.053333,
    height: ScreenHeight * 0.018473,
    marginLeft: ScreenWidth * 0.0053333
  },
  heart5: {
    marginRight: ScreenWidth * 0.24
  },
  affinityTag: {
    position: "absolute",
    top: ScreenHeight * -0.043103,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.026667,
    color: "#279AF1",
    padding: ScreenWidth * 0.013333,
    borderColor: "#279AF1",
    borderWidth: 1,
    borderRadius: ScreenWidth * 0.013333
  },
  venueRatings: {
    position: "relative",
    height: ScreenHeight * 0.030788,
    marginTop: ScreenHeight * 0.0024631,
    display: "inline-block"
  },
  starIcon: {
    position: "relative",
    top: ScreenHeight * 0.0024631,
    width: ScreenWidth * 0.037333,
    height: ScreenHeight * 0.017241,
    marginLeft: ScreenWidth * 0.010667,
    marginRight: ScreenWidth * 0.010667,
    display: "inline-block"
  },
  venueRatingsLabel: {
    position: "relative",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.032,
    color: "#000",
    textAlign: "left"
  },
  timeCostBox: {
    display: "inline-block",
    marginTop: ScreenHeight * 0.024631,
    borderTopColor: "#BABABA",
    borderTopWidth: 1
  },
  calendarIcon: {
    display: "inline-block",
    top: ScreenHeight * 0.0012315,
    width: ScreenWidth * 0.037333,
    height: ScreenHeight * 0.017241,
    marginTop: ScreenHeight * 0.018473,
    marginLeft: ScreenWidth * 0.010667,
    marginRight: ScreenWidth * 0.021333
  },
  calendarLabel: {
    display: "inline-block",
    width: ScreenWidth * 0.78667,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  timeIcon: {
    top: ScreenHeight * 0.0024631,
    width: ScreenWidth * 0.037333,
    height: ScreenHeight * 0.017241,
    marginTop: ScreenHeight * 0.012315,
    marginLeft: ScreenWidth * 0.010667,
    marginRight: ScreenWidth * 0.021333,
    display: "inline-block"
  },
  timeLabel: {
    display: "inline-block",
    width: ScreenWidth * 0.78667,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  durationLabel: {
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#959595",
    textAlign: "left"
  },
  attendeesButton: {
    width: "100%",
    marginTop: ScreenHeight * 0.012315,
    display: "inline-block",
  },
  attendeesIcon: {
    top: ScreenHeight * 0.0024631,
    width: ScreenWidth * 0.037333,
    height: ScreenHeight * 0.017241,
    marginLeft: ScreenWidth * 0.010667,
    marginRight: ScreenWidth * 0.021333,
    display: "inline-block"
  },
  attendeesLabel: {
    display: "inline-block",
    borderBottomColor: "#959595",
    borderBottomWidth: 2,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  costBox: {
    width: "100%",
    marginTop: ScreenHeight * 0.012315,
    display: "inline-block",
  },
  costIcon: {
    position: "absolute",
    top: ScreenHeight * 0.0036946,
    width: ScreenWidth * 0.037333,
    height: ScreenHeight * 0.017241,
    marginLeft: ScreenWidth * 0.010667,
    marginRight: ScreenWidth * 0.021333
  },
  costLabel: {
    display: "inline-block",
    width: ScreenWidth * 0.78667,
    marginLeft: ScreenWidth * 0.069333,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  reimbursementReminderLabel: {
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#959595",
    textAlign: "left"
  },
  airgap: {
    width: ScreenWidth,
    height: ScreenHeight * 0.011084,
    marginLeft: ScreenWidth * -0.064,
    marginTop: ScreenHeight * 0.024631,
    marginBottom: ScreenHeight * 0.024631,
    background: "#F4F4F4"
  },
  eventInfoLabel: {
    display: "inline-block",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.048,
    fontWeight: 700,
    color: "#000",
    textAlign: "left"
  },
  dividerLine: {
    display: "inline-block",
    width: "100%",
    marginTop: ScreenHeight * 0.024631,
    marginBottom: ScreenHeight * 0.024631,
    borderTopColor: "#BABABA",
    borderTopWidth: 1
  },
  vendorBox: {
    display: "inline-block",
    marginTop: ScreenHeight * 0.024631
  },
  vendorLabel: {
    display: "inline-block",
    width: ScreenWidth * 0.78667,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 700,
    color: "#000",
    textAlign: "left"
  },
  vendorNameLabel: {
    display: "inline-block",
    width: ScreenWidth * 0.78667,
    marginTop: ScreenHeight * 0.0098522,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  vendorWebsiteIcon: {
    position: "absolute",
    top: ScreenHeight * 0.014778,
    left: ScreenWidth * 0.808,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  venueBox: {
    display: "inline-block"
  },
  directionsIcon: {
    position: "absolute",
    top: ScreenHeight * 0.0098522,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  venueNameLabel: {
    display: "inline-block",
    width: ScreenWidth * 0.72,
    marginLeft: ScreenWidth * 0.096,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  venueAddressLabel: {
    display: "inline-block",
    width: ScreenWidth * 0.72,
    marginLeft: ScreenWidth * 0.096,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.029333,
    color: "#959595",
    textAlign: "left"
  },
  mapBox: {
    display: "inline-block"
  },
  mapIcon: {
    position: "absolute",
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  mapLabel: {
    display: "inline-block",
    width: ScreenWidth * 0.72,
    marginLeft: ScreenWidth * 0.096,
    marginTop: ScreenHeight * 0.0036946,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  sendIcon: {
    position: "absolute",
    left: ScreenWidth * 0.808,
    top: 0,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  meetingLocationHeader: {
    display: "inline-block",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.048,
    fontWeight: 700,
    color: "#000",
    textAlign: "left"
  },
  meetingLocationPolicy: {
    display: "inline-block",
    marginTop: ScreenHeight * 0.024631,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  reimbursementHeader: {
    display: "inline-block",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.048,
    fontWeight: 700,
    color: "#000",
    textAlign: "left"
  },
  reimbursementPolicy: {
    display: "inline-block",
    marginTop: ScreenHeight * 0.024631,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  noShowHeader: {
    display: "inline-block",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.048,
    fontWeight: 700,
    color: "#000",
    textAlign: "left"
  },
  noShowPolicy: {
    display: "inline-block",
    marginTop: ScreenHeight * 0.024631,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  },
  attendanceHeader: {
    display: "inline-block",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.048,
    fontWeight: 700,
    color: "#000",
    textAlign: "left"
  },
  attendancePolicy: {
    display: "inline-block",
    marginTop: ScreenHeight * 0.024631,
    marginBottom: ScreenHeight * 0.024631,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000",
    textAlign: "left"
  }
});

const chatPage = StyleSheet.create({
  eventTitle: {
    position: "absolute",
    left: ScreenWidth * 0.170667,
    top: ScreenHeight * 0.051724,
    width: ScreenWidth * 0.66667,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 600,
    textAlign: "left",
    color: "#000",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  scroller: {
    position: "absolute",
    top: ScreenHeight * 0.10099,
    width: ScreenWidth,
    height: ScreenHeight * 0.80542,
    borderColor: "#dedede",
    borderBottomWidth: 1,
    borderTopWidth: 1
  },
  chatBlock: {
    position: "relative",
    width: "100%",
    marginTop: ScreenHeight * 0.0061576
  },
  speakerBlock: {
    position: "relative",
    width: "100%",
    marginTop: ScreenHeight * 0.0061576,
    marginBottom: ScreenHeight * -0.0036946
  },
  character: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: 0,
    width: ScreenWidth * 0.096,
    height: ScreenHeight * 0.044335
  },
  myCharacter: {
    left: ScreenWidth * 0.86133,
  },
  speakerLabel: {
    position: "relative",
    marginLeft: ScreenWidth * 0.17067,
    width: ScreenWidth * 0.65867,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.029333,
    fontWeight: 400,
    textAlign: "left",
    color: "#959595"
  },
  locationLabel: {
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.029333,
    fontWeight: 400,
    color: "#7c7c7c",
    paddingHorizontal: ScreenWidth * 0.026667
  },
  checkinStrip: {
    position: "absolute",
    marginLeft: ScreenWidth * 0.042667,
    marginRight: ScreenWidth * 0.042667,
    top: "50%",
    width: ScreenWidth * 0.914667,
    height: 0,
    borderColor: "#e1e1e1",
    borderTopWidth: 1
  },
  checkinLabel: {
    position: "relative",
    display: "inline-block",
    textAlign: "center",
    marginLeft: ScreenWidth * 0.17067,
    marginVertical: "auto",
    width: ScreenWidth * 0.65867
  },
  checkinLabelText: {
    lineHeight: ScreenHeight * 0.039409,
    marginVertical: "auto",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.029333,
    fontWeight: 400,
    color: "#7c7c7c",
    backgroundColor: "#fff",
    paddingHorizontal: ScreenWidth * 0.026667
  },
  myLabels: {
    textAlign: "right"
  },
  input: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.92365,
    width: ScreenWidth * 0.82933,
    height: ScreenHeight * 0.036946,
    paddingLeft: ScreenWidth * 0.042667,
    paddingRight: ScreenWidth * 0.042667,
    borderRadius: ScreenWidth * 0.053333
  },
  inputLabel: {
    position: "absolute",
    left: ScreenWidth * 0.082667,
    top: ScreenHeight * 0.93103
  },
  inputBackground: {
    backgroundColor: "#f2f2f2"
  },
  sendButton: {
    position: "absolute",
    left: ScreenWidth * 0.872,
    top: ScreenHeight * 0.91379,
    width: ScreenWidth * 0.128,
    height: ScreenHeight * 0.059113
  },
  sendButtonIcon: {
    position: "absolute",
    left: ScreenWidth * 0.032,
    top: ScreenHeight * 0.014778,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  chatMessageLabel: {
    position: "relative",
    marginLeft: ScreenWidth * 0.17067,
    paddingVertical: ScreenHeight * 0.0061576,
    paddingHorizontal: ScreenWidth * 0.026667,
    width: ScreenWidth * 0.57333,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    color: "#000",
    backgroundColor: "#f2f2f2",
    borderRadius: ScreenWidth * 0.018667
  },
  myChatMessageLabel: {
    marginLeft: ScreenWidth * 0.256,
    color: "#fff",
    backgroundColor: "#5461cb"
  },
  backButton: {
    left: ScreenWidth * 0.050667,
    top: ScreenHeight * 0.04064
  },
  helpButton: {
    left: ScreenWidth * 0.856,
    top: ScreenHeight * 0.04064
  },
  helpButtonIcon: {
    position: "absolute",
    left: ScreenWidth * 0.013333,
    top: ScreenHeight * 0.0061576,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  roundButton: {
    borderColor: "#279af1",
    borderRadius: ScreenWidth * 0.058667,
    borderWidth: 1
  },
  messageButton: {
    position: "relative",
    marginLeft: ScreenWidth * 0.17067,
    padding: ScreenWidth * 0.026667,
    width: ScreenWidth * 0.57333,
    height: ScreenHeight * 0.049261,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    color: "#000",
    backgroundColor: "#fff",
    borderBottom: "1px solid #ececec"
  },
  messageButtonIcon: {
    position: "absolute",
    left: ScreenWidth * 0.48267,
    top: ScreenHeight * 0.0098522,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  messageButtonTop: {
    borderTopLeftRadius: ScreenWidth * 0.018667,
    borderTopRightRadius: ScreenWidth * 0.018667
  },
  messageButtonBottom: {
    borderBottomLeftRadius: ScreenWidth * 0.018667,
    borderBottomRightRadius: ScreenWidth * 0.018667,
    borderBottom: 0
  },
  myChatMessageButton: {
    marginLeft: ScreenWidth * 0.256,
  },
  attendeeBlock: {
    position: "relative",
    height: ScreenHeight * 0.044335,
    width: "100%",
    marginTop: ScreenHeight * 0.0061576
  },
  attendeeCharacter: {
    left: ScreenWidth * 0.10667
  },
  attendeeNameBox: {
    position: "absolute",
    left: ScreenWidth * 0.22667,
    top: 0,
    height: "100%",
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  attendeeName: {
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400
  },
  headingBox: {
    position: "absolute",
    left: ScreenWidth * 0.10667,
    top: 0,
    height: "100%",
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  heading: {
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 700
  }
});

const surveyPage = StyleSheet.create({
  question: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    width: ScreenWidth * 0.91467,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 400,
    color: "#000"
  },
  attendQ: {
    top: ScreenHeight * 0.14039
  },
  eventQ: {
    top: ScreenHeight * 0.30172
  },
  groupQ: {
    top: ScreenHeight * 0.42611
  },
  commentQ: {
    top: ScreenHeight * 0.55665
  },
  attendBox: {
    position: "absolute",
    display: "inline-block",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.19828,
    width: ScreenWidth * 0.91467
  },
  attendButton: {
    position: "relative",
    display: "inline-block",
    width: ScreenWidth * 0.44267,
    height: ScreenHeight * 0.067734,
    justifyContent: "center"
  },
  attendGap: {
    marginRight: ScreenWidth * 0.026667
  },
  blocker: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.30049,
    width: "100%",
    height: ScreenHeight * 0.44335,
    backgroundColor: "#fff",
    opacity: 0.85
  },
  bookingRatingBox: {
    position: "absolute",
    display: "inline-block",
    left: ScreenWidth * 0.28,
    top: ScreenHeight * 0.35961,
    width: ScreenWidth * 0.48
  },
  groupRatingBox: {
    position: "absolute",
    display: "inline-block",
    left: ScreenWidth * 0.28,
    top: ScreenHeight * 0.49015,
    width: ScreenWidth * 0.48
  },
  ratingButton: {
    position: "relative",
    display: "inline-block",
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.022167,
    marginRight: ScreenWidth * 0.029333
  },
  ratingCaption: {
    position: "absolute",
    width: ScreenWidth * 0.16,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.024,
    fontWeight: 600,
    textAlign: "center",
    color: "#959595"
  },
  eventHatedIt: {
    left: ScreenWidth * 0.232,
    top: ScreenHeight * 0.3867
  },
  eventLovedIt: {
    left: ScreenWidth * 0.60533,
    top: ScreenHeight * 0.3867
  },
  groupHatedIt: {
    left: ScreenWidth * 0.232,
    top: ScreenHeight * 0.51724
  },
  groupLovedIt: {
    left: ScreenWidth * 0.60533,
    top: ScreenHeight * 0.51724
  },
  commentLabel: {
    left: ScreenWidth * 0.072,
    top: ScreenHeight * 0.63177
  },
  commentInput: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.617,
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.098522,
    padding: ScreenWidth * 0.026667,
    borderColor: "#dedede",
    borderWidth: 1,
    borderRadius: ScreenWidth * 0.026667
  },
  submitButton: {
    position: "absolute",
    left: ScreenWidth * 0.18133,
    top: ScreenHeight * 0.75123,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  cancelButton: {
    position: "absolute",
    left: ScreenWidth * 0.18133,
    top: ScreenHeight * 0.82635,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const landscapeEvent = StyleSheet.create({
  panel: {
    width: ScreenWidth * 0.43733,
    backgroundColor:"#fff",
    marginBottom: ScreenHeight * 0.013547,
    marginRight: ScreenWidth * 0.04
  },
  needsAction: {
    //backgroundColor:"#cdcdff"
  },
  image: {
    width: ScreenWidth * 0.43733,
    height: ScreenHeight * 0.13301,
    borderColor: "#fff",
    borderRadius: ScreenWidth * 0.032,
    borderStyle: "solid",
    borderWidth: 0
  },
  title: {
    paddingTop: ScreenHeight * 0.01601,
    width: ScreenWidth * 0.43733,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000"
  },
  date: {
    paddingTop: ScreenHeight * 0.0049261,
    width: ScreenWidth * 0.43733,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.029333,
    color: "#959595"
  },
  likeButtonTouchable: {
    position: "absolute",
    left: ScreenWidth * 0.36533,
    top: ScreenHeight * 0.012315,
  },
  likeButton: {
    width: ScreenWidth * 0.048,
    height: ScreenHeight * 0.01601
  },
  containerBig: {
    width: ScreenWidth * 0.91467,
    whiteSpace: "no-wrap",
    paddingBottom: "1vh",
  },
  panelBig: {
    width: ScreenWidth * 0.91467,
    backgroundColor:"#fff",
  },
  imageBig: {
    position: "relative",
    display: "block",
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.27833,
    borderColor: "#fff",
    borderRadius: ScreenWidth * 0.032,
    borderStyle: "solid",
    borderWidth: 0
  },
  titleBig: {
    position: "relative",
    display: "block",
    paddingTop: "1vh",
    width: ScreenWidth * 0.91467,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000"
  },
  dateBig: {
    position: "relative",
    display: "block",
    paddingTop: ScreenHeight * 0.0049261,
    paddingBottom: "1vh",
    width: ScreenWidth * 0.91467,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.029333,
    color: "#959595",
  },
  likeButtonBig: {
    position: "absolute",
    top: "-26.5vh",
    right: "2%",
    width: ScreenWidth * 0.048,
    height: ScreenHeight * 0.01601,
  },
  buttonContainerBig: {
    position: "relative",
    display: "inline-block",
    flexDirection: "row",
    whiteSpace: "no-wrap",
  },
  acceptButton: {
    position: "relative",
    display: "inline-block",
    width: "45%",
    textAlign: "center",
    height: ScreenHeight * 0.045567,
    lineHeight: ScreenHeight * 0.045567,
    borderRadius: ScreenWidth * 0.08,
    whiteSpace: "no-wrap",
  },
  buttonSpacer: {
    width: "4%",
    display: "inline-block",
    position: "relative",
    whiteSpace: "no-wrap",
  },
  declineButton: {
    position: "relative",
    display: "inline-block",
    width: "45%",
    textAlign: "center",
    height: ScreenHeight * 0.045567,
    lineHeight: ScreenHeight * 0.045567,
    borderRadius: ScreenWidth * 0.08,
    whiteSpace: "no-wrap",
  },
  separator: {
    width: ScreenWidth * 0.91467,
    height: ScreenHeight * 0.013547
  }
});

const chatHelpPopup = StyleSheet.create({
  panel: {
    position: "absolute",
    left: ScreenWidth * 0.056,
    top: ScreenHeight * 0.27833,
    width: ScreenWidth * 0.888,
    height: ScreenHeight * 0.44335,
    backgroundColor:"#fff",
    borderRadius: ScreenWidth * 0.04
  },
  title: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.036946,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.058667,
    fontWeight: 700,
    color: "#279af1"
  },
  description: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.086207,
    width: "100%",
    paddingHorizontal: ScreenWidth * 0.042667,
    textAlign: "left",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 500,
    color: "#3f3f3f"
  },
  scroller: {
    height: ScreenHeight * 0.27832
  },
  closeButton: {
    position: "absolute",
    left: ScreenWidth * 0.12533,
    top: ScreenHeight * 0.37562,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const reportChatPopup = StyleSheet.create({
  panel: {
    position: "absolute",
    left: ScreenWidth * 0.056,
    top: ScreenHeight * 0.37315,
    width: ScreenWidth * 0.888,
    height: ScreenHeight * 0.25369,
    backgroundColor:"#fff",
    borderRadius: ScreenWidth * 0.04
  },
  cancelButton: {
    position: "absolute",
    left: ScreenWidth * 0.05333,
    top: ScreenHeight * 0.16626,
    width: ScreenWidth * 0.37867,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  confirmButton: {
    position: "absolute",
    left: ScreenWidth * 0.456,
    top: ScreenHeight * 0.16626,
    width: ScreenWidth * 0.37867,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const confirmDeclineEventPopup = StyleSheet.create({
  panel: {
    position: "absolute",
    left: ScreenWidth * 0.056,
    top: ScreenHeight * 0.125,
    width: ScreenWidth * 0.888,
    height: ScreenHeight * 0.85,
    backgroundColor:"#fff",
    borderRadius: ScreenWidth * 0.04
  },
  title: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.036946,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.085333,
    fontWeight: 700,
    color: "#279af1"
  },
  character: {
    position: "absolute",
    left: ScreenWidth * 0.304,
    top: ScreenHeight * 0.225,
    width: ScreenWidth * 0.27733,
    height: ScreenHeight * 0.17118
  },
  subtitle: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.105,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 500,
    color: "#3f3f3f"
  },
  description: {
    position: "absolute",
    left: 0,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 500,
    color: "#3f3f3f"
  },
  selectionGroup: {
    position: "relative",
    paddingLeft: ScreenWidth * 0.05333,
    paddingRight: ScreenWidth * 0.05333,
    top: ScreenHeight * 0.165
  },
  choiceGroup: {
  },
  commentBox: {
    position: "relative",
    marginTop: ScreenHeight * 0.0086207,
  },
  hide: {
    display: "none"
  },
  reasonLabel: {
    marginTop: ScreenHeight * 0.0086207,
    top: "0px !important"
  },
  commentInput: {
    top: '0px',
    width: ScreenWidth * 0.695,
  },
  eventBox: {
    position: "absolute",
    display: "inline-block",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.4125,
  },
  question: {
    top: ScreenHeight * 0.335,
    textAlign: "center",
    width: ScreenWidth * 0.85,
  },
  cancelButton: {
    position: "absolute",
    left: ScreenWidth * 0.05333,
    top: ScreenHeight * 0.785,
    width: ScreenWidth * 0.37867,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  confirmButton: {
    position: "absolute",
    left: ScreenWidth * 0.456,
    top: ScreenHeight * 0.785,
    width: ScreenWidth * 0.37867,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  halfButton: {
    width: ScreenWidth * 0.385,
  }
});

const confirmDeclinePopup = StyleSheet.create({
  panel: {
    position: "absolute",
    left: ScreenWidth * 0.056,
    top: ScreenHeight * 0.38547,
    width: ScreenWidth * 0.888,
    height: ScreenHeight * 0.22873,
    backgroundColor:"#fff",
    borderRadius: ScreenWidth * 0.04
  },
  title: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.036946,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.058667,
    fontWeight: 700,
    color: "#279af1"
  },
  description: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.086207,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 500,
    color: "#3f3f3f"
  },
  cancelButton: {
    position: "absolute",
    left: ScreenWidth * 0.05333,
    top: ScreenHeight * 0.14163,
    width: ScreenWidth * 0.37867,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  confirmButton: {
    position: "absolute",
    left: ScreenWidth * 0.456,
    top: ScreenHeight * 0.14163,
    width: ScreenWidth * 0.37867,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const acceptedPopup = StyleSheet.create({
  panel: {
    position: "absolute",
    left: ScreenWidth * 0.056,
    top: ScreenHeight * 0.11084,
    width: ScreenWidth * 0.888,
    height: ScreenHeight * 0.77833,
    backgroundColor:"#fff",
    borderRadius: ScreenWidth * 0.04
  },
  title: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.083744,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.085333,
    fontWeight: 700,
    color: "#279af1"
  },
  character: {
    position: "absolute",
    left: ScreenWidth * 0.30667,
    top: ScreenHeight * 0.17488,
    width: ScreenWidth * 0.26933,
    height: ScreenHeight * 0.16626
  },
  description: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.38054,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.042667,
    fontWeight: 500,
    color: "#3f3f3f"
  },
  calendarButton: {
    position: "absolute",
    left: ScreenWidth * 0.12533,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08,
    borderWidth: 1
  },
  calendarButtonLabel: {
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.042667,
    fontWeight: 400,
  },
  iCalButton: {
    top: ScreenHeight * 0.45936,
    borderColor: "#279af1"
  },
  iCalLabel: {
    margin: "auto",
    color: "#279af1"
  },
  outlookButton: {
    top: ScreenHeight * 0.52463,
    borderColor: "#d66666"
  },
  outlookLabel: {
    margin: "auto",
    color: "#d66666"
  },
  googleButton: {
    top: ScreenHeight * 0.5899,
    borderColor: "#54bb24"
  },
  googleLabel: {
    margin: "auto",
    color: "#54bb24"
  },
  closeButton: {
    position: "absolute",
    left: ScreenWidth * 0.12533,
    top: ScreenHeight * 0.68842,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const calendarPopup = StyleSheet.create({
  iCalButton: {
    top: ScreenHeight * 0.12685,
    borderColor: "#279af1"
  },
  outlookButton: {
    top: ScreenHeight * 0.19212,
    borderColor: "#d66666"
  },
  googleButton: {
    top: ScreenHeight * 0.25739,
    borderColor: "#54bb24"
  },
  closeButton: {
    position: "absolute",
    left: ScreenWidth * 0.12533,
    top: ScreenHeight * 0.35591,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const helpPopup = StyleSheet.create({
  panel: {
    position: "absolute",
    left: ScreenWidth * 0.056,
    top: ScreenHeight * 0.22537,
    width: ScreenWidth * 0.888,
    height: ScreenHeight * 0.54926,
    backgroundColor:"#fff",
    borderRadius: ScreenWidth * 0.04
  },
  title: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.083744,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.085333,
    fontWeight: 700,
    color: "#279af1"
  },
  character: {
    position: "absolute",
    left: ScreenWidth * 0.30667,
    top: ScreenHeight * 0.17488,
    width: ScreenWidth * 0.26933,
    height: ScreenHeight * 0.16626
  },
  description: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.38054,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.042667,
    fontWeight: 500,
    color: "#3f3f3f"
  },
  closeButton: {
    position: "absolute",
    left: ScreenWidth * 0.12533,
    top: ScreenHeight * 0.45936,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const declinePopup = StyleSheet.create({
  panel: {
    position: "absolute",
    left: ScreenWidth * 0.056,
    top: ScreenHeight * 0.19034,
    width: ScreenWidth * 0.888,
    height: ScreenHeight * 0.62931,
    backgroundColor:"#fff",
    borderRadius: ScreenWidth * 0.04
  },
  title: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.071429,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.085333,
    fontWeight: 700,
    color: "#279af1"
  },
  character: {
    position: "absolute",
    left: ScreenWidth * 0.304,
    top: ScreenHeight * 0.16749,
    width: ScreenWidth * 0.27733,
    height: ScreenHeight * 0.17118
  },
  description: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.37069,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 500,
    color: "#3f3f3f"
  },
  closeButton: {
    position: "absolute",
    left: ScreenWidth * 0.12533,
    top: ScreenHeight * 0.51108,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const submitRatingPopup = StyleSheet.create({
  panel: {
    position: "absolute",
    left: ScreenWidth * 0.056,
    top: ScreenHeight * 0.19034,
    width: ScreenWidth * 0.888,
    height: ScreenHeight * 0.62931,
    backgroundColor:"#fff",
    borderRadius: ScreenWidth * 0.04,
    justifyContent: "space-evenly",
    display: "flex",
    flexDirection: "column"
  },
  title: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    left: 0,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.085333,
    fontWeight: 700,
    color: "#279af1"
  },
  character: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    left: ScreenWidth * 0.30667,
    width: ScreenWidth * 0.26933,
    height: ScreenHeight * 0.16626
  },
  description: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    left: 0,
    width: "100%",
    padding: "10px !important",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 500,
    color: "#3f3f3f"
  },
  closeButton: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    left: ScreenWidth * 0.12533,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const checkinPopup = StyleSheet.create({
  panel: {
    position: "absolute",
    left: ScreenWidth * 0.056,
    top: ScreenHeight * 0.15517,
    width: ScreenWidth * 0.888,
    height: ScreenHeight * 0.68842,
    backgroundColor:"#fff",
    borderRadius: ScreenWidth * 0.04
  },
  title: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.068966,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.085333,
    fontWeight: 700,
    color: "#279af1"
  },
  character: {
    position: "absolute",
    left: ScreenWidth * 0.29867,
    top: ScreenHeight * 0.16995,
    width: ScreenWidth * 0.29067,
    height: ScreenHeight * 0.17857
  },
  description: {
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.37808,
    width: "100%",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    fontWeight: 500,
    color: "#3f3f3f"
  },
  chatButton: {
    position: "absolute",
    left: ScreenWidth * 0.12533,
    top: ScreenHeight * 0.53202,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  },
  closeButton: {
    position: "absolute",
    left: ScreenWidth * 0.12533,
    top: ScreenHeight * 0.59483,
    width: ScreenWidth * 0.63733,
    height: ScreenHeight * 0.049261,
    borderRadius: ScreenWidth * 0.08
  }
});

const dropDownStyles = StyleSheet.create({
  dropdown: {
    margin: 16,
    height: 50,
    borderBottomColor: 'gray',
    borderBottomWidth: 0.5,
  },
  icon: {
    marginRight: 5,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
});

const styles = StyleSheet.create({
  fillHeight: {
    height: ScreenHeight,
    alignItems: "center"
  },
  mainContainer: {
    backgroundColor: "#fff",
    flex: 1
  },
  smallIcon: {
    position: "absolute",
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557
  },
  bottomNav: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignContent: "space-evenly",
    position: "absolute",
    left: 0,
    top: ScreenHeight * 0.90764,
    width: ScreenWidth,
    height: ScreenHeight * 0.09236,
    backgroundColor: "#fff",
    borderTopColor: "#d4d4d4",
    borderTopWidth: 1
  },
  buttonInactive: {
    backgroundColor: "#aeaeae"
  },
  buttonActive: {
    backgroundColor: "#5461cb"
  },
  whiteButtonActive: {
    borderWidth: 1,
    borderColor: "#5461cb",
    backgroundColor: "#fff"
  },
  whiteButtonInactive: {
    borderWidth: 1,
    borderColor: "#aeaeae",
    backgroundColor: "#aeaeae"
  },
  whiteButtonLabel: {
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.042667,
    fontWeight: 500,
    margin: "auto",
    color: "#fff",
  },
  blueButtonLabel: {
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.042667,
    fontWeight: 500,
    margin: "auto",
    color: "#5461cb"
  },
  boundWidth: {
    width: ScreenWidth
  },
  scroller: {
    width: ScreenWidth,
    height: ScreenHeight
  },
  bold: {
    fontWeight: 700
  },
  characterImage: {
    position: "absolute",
    left: ScreenWidth * 0.048,
    top: ScreenHeight * 0.022167,
    width: ScreenWidth * 0.15467,
    height: ScreenHeight * 0.071429
  },
  characterLabel: {
    position: "absolute",
    left: ScreenWidth * 0.23733,
    width: ScreenWidth * 0.62133,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#959595"
  },
  characterLabelLong: {
    position: "absolute",
    left: ScreenWidth * 0.23733,
    top: ScreenHeight * 0.017241,
    width: ScreenWidth * 0.62133,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#959595"
  },
  navButtonIcon: {
    position: "absolute",
    left: ScreenWidth * 0.048,
    top: ScreenHeight * 0.008621,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.02956
  },
  navButtonCaption: {
    position: "absolute",
    top: ScreenHeight * 0.050493,
    width: "100%",
    textAlign: "center",
	fontFamily: "Lato",
	fontSize: ScreenWidth * 0.024
  },
  navButtonCaptionActive: {
    color: "#279af1"
  },
  navButtonCaptionInactive: {
    color: "#aeaeae"
  },
  navButton: {
    position: "relative",
    top: ScreenHeight * 0.0086207,
    width: ScreenWidth * 0.16,
    height: ScreenHeight * 0.073892
  },
  popup: {
    position: "absolute",
    width: ScreenWidth,
    height: ScreenHeight,
  },
  popupPanel: {
    position: "absolute",
    width: ScreenWidth,
    height: ScreenHeight,
    backgroundColor: "#292929",
    opacity: 0.52
  },
  roundButton: {
    position: "absolute",
    width: ScreenWidth * 0.093333,
    height: ScreenHeight * 0.043103,
    backgroundColor: "#fff",
    borderRadius: ScreenWidth * 0.046667
  },
  portraitEventImage: {
    position: "absolute",
    left: 0,
    top: 0,
    width: ScreenWidth,
    height: ScreenHeight * 0.63793
  },
  radioContainer: {
    width: "100%",
    display: "inline-block",
    verticalAlign: "middle",
    paddingTop: ScreenHeight * 0.019704,
    paddingBottom: ScreenHeight * 0.019704,
    borderRadius: ScreenWidth * 0.026667,
    borderWidth: 1
  },
  radioContainerSkinny: {
    paddingTop: ScreenHeight * 0.01601,
    paddingBottom: ScreenHeight * 0.01601
  },
  radioContainerOn: {
    borderColor: "#279af1"
  },
  radioContainerOff: {
    borderColor: "#dedede"
  },
  radioButton: {
    position: "relative",
    display: "inline-block",
    verticalAlign: "middle",
    left: ScreenWidth * 0.042667,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557,
    borderRadius: ScreenWidth * 0.032
  },
  radioButtonSkinny: {
  },
  radioButtonOn: {
    borderColor: "#279af1",
    borderWidth: ScreenWidth * 0.016
  },
  radioButtonOff: {
    borderColor: "#c4c4c4",
    borderWidth: 1
  },
  radioButtonText: {
    position: "relative",
    display: "inline-block",
    left: ScreenWidth * 0.085333,
    width: ScreenWidth * 0.62933,
    verticalAlign: "middle",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000"
  },
  radioButtonTextSkinny: {
  },
  surveyRadioButtonText: {
    position: "relative",
    display: "inline-block",
    left: ScreenWidth * 0.085333,
    width: ScreenWidth * 0.24533,
    verticalAlign: "middle",
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.037333,
    color: "#000"
  },
  checkboxButton: {
    position: "relative",
    display: "inline-block",
    verticalAlign: "middle",
    left: ScreenWidth * 0.042667,
    width: ScreenWidth * 0.064,
    height: ScreenHeight * 0.029557,
    borderRadius: ScreenWidth * 0.008
  },
  checkboxButtonSkinny: {
  },
  checkboxButtonOn: {
    backgroundColor: "#279af1"
  },
  checkboxButtonIcon: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%"
  },
  pageLabel: {
    position: "absolute",
    left: ScreenWidth * 0.042667,
    top: ScreenHeight * 0.066502,
    fontFamily: "Lato",
    fontSize: ScreenWidth * 0.058667,
    fontWeight: 700,
    color: "#000"
  },
  rewindable: {
    left: ScreenWidth * 0.16
  },
  backButton: {
    position: "absolute",
    left: ScreenWidth * 0.053333,
    top: ScreenHeight * 0.062808
  }
});

export default App;
